import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import { LinkListModel } from 'src/app/core/models/layout/sidenav-model';
import { AuthService } from 'src/app/core/services/auth.service';
import {fadeInLeftOnEnterAnimation,fadeOutLeftOnLeaveAnimation,collapseOnLeaveAnimation,expandOnEnterAnimation} from 'angular-animations';
import { EnabledLinksUser } from 'src/app/core/models/Affiliate/headerLinks';
import { NavigationEnd, Router } from '@angular/router';
import { HeaderDropdownService } from 'src/app/core/services/headerdropdown/header-dropdown-service.service';

@Component({
    selector: 'app-header-public',
    templateUrl: './header-public.component.html',
    styleUrl: './header-public.component.scss',
    animations: [
        fadeInLeftOnEnterAnimation({ duration: 400 }),
        fadeOutLeftOnLeaveAnimation(),
        collapseOnLeaveAnimation(),
        expandOnEnterAnimation(),
    ],
    standalone: false
})
export class HeaderPublicComponent {
  @Input() isLoggedIn: boolean;
  @Input() name: string;
  @Input() burgerMenuActive: boolean;
  @Input() isLogin!: boolean;
  @Output() openedEvent = new EventEmitter<boolean>();

  public linksEnabled: EnabledLinksUser = JSON.parse(localStorage.getItem('linksEnabled'));
  public isChecked = false;
  public isOpenBurger: boolean = false;
  public deploy: boolean = false;
  public currentUrl: string = '';
  public isBlueTheme: boolean = false;
  public publicClientLinklist: LinkListModel[];
  public publicBusinessLinklist: LinkListModel[];
  public publicAboutLinkList: LinkListModel[];
  public activeLinkList: string = 'clients';
  public isClientOpen: boolean = false;
  public isBusinessOpen: boolean = false;
  public isResourcesClientOpen: boolean = false;
  public isResourcesBusinessOpen: boolean = false;
  public isAboutOpen: boolean = false;


  toggleClient() {
    this.isClientOpen = !this.isClientOpen
  }

  toggleBusiness() {
    this.isBusinessOpen = !this.isBusinessOpen
  }

  toggleResourcesClient() {
    this.isResourcesClientOpen = !this.isResourcesClientOpen
  }

  toggleResourcesBusiness() {
    this.isResourcesBusinessOpen = !this.isResourcesBusinessOpen
  }

  toggleAbout() {
    this.isAboutOpen = !this.isAboutOpen
  }

  constructor(
    private auth: AuthService,
    private eRef: ElementRef,
    private router: Router,
    public dropdownService: HeaderDropdownService,
  ) {}

  setActiveLinkList(listName: string) {
    this.activeLinkList = listName;
  }

  closeAllDropdowns() {
    this.dropdownService.closeAllDropdowns();
  }

  ngOnInit(){
    this.links()
  }

  ngOnChanges(){
    this.links()
  }

  links() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
        this.setBlueTheme(this.currentUrl);
      }
    });
    this.publicClientLinklist = [
      {
        title: 'Préstamo de consumo',
        subtitle: 'Paga tus compras a tu rimo, con plazos de hasta 10 años.',
        route: '/prestamo-de-consumo'
      },
      {
        title: 'Préstamo personal',
        subtitle: 'Cuéntanos tu proyecto y te ayudaremos a hacerlo realidad.',
        route: '/prestamo-personal',
      },
      {
        title: 'Reunificación de deudas',
        subtitle:
          'Unifica todos tus préstamos y paga hasta un 75% menos cada mes.',
        route: '/reunificacion-deudas',
      },
      {
        title: 'Anticipo rentas de alquiler',
        subtitle:
          'Evalúa a tus inquilinos y asegúrate de recibir todas las rentas.',
        route: '/anticipo-alquiler',
      },
      {
        title: 'Ahorro e inversión',
        subtitle: 'Saca el máximo provecho de tu dinero, hoy y mañana. ',
        route: '/ahorro-inversion',
      },
      {
        title: 'Tarjeta de pago aplazado',
        subtitle:
          'Paga con las condiciones que tú eligas y ajústalas siempre que quieras.',
        route: '/tarjeta-pago-aplazado',
      },
      {
        title: 'Pagarés',
        subtitle:
          'Obtén el crédito que necesitas y accede a las mejores soluciones de inversión.',
        route: '/pagares',
      },
    ];
    this.publicBusinessLinklist = [
      {
        title: 'Concesionario Coches',
        route: '/concesionario-coches',
      },
      {
        title: 'Concesionario Motos',
        route: '/documentos',
      },
      {
        title: 'Comercio',
        route: '/documentos',
      },
      {
        title: 'Clínica',
        route: '/documentos',
      },
      {
        title: 'Club deportivo',
        route: '/documentos',
      },
      {
        title: 'E-commerce',
        route: '/documentos',
      },
      {
        title: 'Centro educativo',
        route: '/documentos',
      },
      {
        title: 'Circulante canal HORECA',
        route: '/documentos',
      },
    ];
    this.publicAboutLinkList = [
      {
        title: 'Nuestra App',
        subtitle: 'En Confia no existe el “papeleo”. Todo lo que necesitas está en tu móvil.',
        route: '/app',
      },
      {
        title: '¿Quiénes somos?',
        subtitle: 'Te contamos un poco más sobre nosotros para irnos conociendo mejor.',
        route: '/sobre-nosotros',
      },
    ]
  }
  
  setBlueTheme(url: string): void {
    const blueThemeRoutes = [
      '/prestamo-de-consumo',
      '/prestamo-personal',
      '/tarjeta-pago-aplazado',
      '/ahorro-inversion',
      '/anticipo-alquiler',
      '/pagares',
      '/reunificacion-deudas',
      '/concesionario-coches',
    ];
    this.isBlueTheme = blueThemeRoutes.includes(url);
  }

  showMenu() {
    this.isOpenBurger = true;
    this.openedEvent.emit(true);
}

hideMenu() {
    this.isOpenBurger = false;
    this.openedEvent.emit(false);
    this.isClientOpen = false;
    this.isBusinessOpen = false;
    this.isResourcesClientOpen = false;
    this.isResourcesBusinessOpen = false;
    this.isAboutOpen = false;
}

  logout() {
    this.auth.logout();
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.deploy = false;
    }
  }
}
