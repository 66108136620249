<div class="carrusel">

  <div class="logos-desktop" *ngIf="!isMobile">
    <div class="item" *ngFor="let item of items">
      <img [src]="item.img" alt="logos" />
    </div>
  </div>

  <owl-carousel-o [options]="logos" *ngIf="isMobile">
    <ng-template carouselSlide *ngFor="let item of items">
      <div class="item">
        <img [src]="item.img" alt="logos" />
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
