import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, Validators} from '@angular/forms';
import { allRegex } from 'src/app/core/extensions/regex';
import { ContactService } from 'src/app/core/services/contact/contact.service';
import { environment } from 'src/environments/environment';
import { HideShowAnimation } from '../../animations/hide-show';
import { RecaptchaService } from 'src/app/core/services/recaptcha/recaptcha.service';

@Component({
    selector: 'app-contact-form-shared',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    animations: [HideShowAnimation],
    standalone: false
})
export class ContactFormComponent implements OnInit {

  public contactForm: FormGroup;
  options: FormGroup;
  isVisible : boolean = false
  public formOpened: boolean = false;
  sitekey : string = environment.recaptcha.siteKey

  status: string;
  alertMessage: string;
  reCAPTCHAToken: any;
  tokenVisible: boolean = false;
  spinnerActive:boolean = false

  constructor(private formBuilder: FormBuilder , private recaptchaService: RecaptchaService, private contactService: ContactService) {
  
  }
  ngOnInit(){
    this.contactForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['',[Validators.required, Validators.pattern(allRegex.regexPhone)]]
    })
  }
 /*  openForm() {
    this.formOpened = true
    this.isVisible = true
  } */
  toggle(){
    this.isVisible = false
    this.isVisible = true
    this.formOpened = !this.formOpened 
  }
  onSubmit(){
    this.status = null
    this.reCAPTCHAToken = this.recaptchaService.execute('registerCustomer').then((token) => {
      this.reCAPTCHAToken = token ;
        this.tokenVisible = true;
        this.spinnerActive = true
      if(this.tokenVisible){
        const contactData = {
          type: 'callme',
          email: this.contactForm.get('email').value,
          mobile: this.contactForm.get('phoneNumber').value,
          gtoken : this.reCAPTCHAToken
        }
    
        this.contactService.contactForm(contactData).subscribe({
        complete: () => {
          this.spinnerActive= false;
              this.status = 'ok'
              this.alertMessage = 'Tu solicitud se envió correctamente.'
              this.contactForm.reset()
              this.formOpened = false;
              this.isVisible = false;
        },
        error: (err) => {
          this.status = 'ko'
          this.alertMessage = 'Hubo un problema con tu petición.'
        },
        next: (res) =>{
          
        }
      })
      }
    })
  }
  
  
}
