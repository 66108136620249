import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header-no-sidenav',
    templateUrl: './header-no-sidenav.component.html',
    styleUrl: './header-no-sidenav.component.scss',
    standalone: false
})
export class HeaderNoSidenavComponent {

  public typeUser = localStorage.getItem('userType');
  public idBorrower = localStorage.getItem('idBorrower');

constructor(private router: Router,){}

  ngOnInit(){}

  navigateTo() {
    if (this.typeUser === 'afiliado') {
      this.router.navigate(['/afiliado']);
    } else if (this.typeUser === 'prestatario' && this.idBorrower) {
      this.router.navigate(['/prestatario']);
    }
  }
  

}
