<form [formGroup]="form" class="sellers-view" [ngStyle]="{'border-style': upload ? 'solid' : 'dashed' }">
  <!--  [ngClass]="{ 'sellers-view': customStyle, 'default-style': !customStyle }" -->
  <div *ngIf="upload && allSelectedFiles.length > 0" class="img-preview">
    <div>
      @if (allSelectedFiles.length
      <= 1) { <img *ngIf="preview && previewIsImg" [src]="preview" alt="preview" />
      <img *ngIf="preview && !previewIsImg" [src]="'../../../../assets/svg/file-icon.svg'" alt="preview" />
      } @else {
      <img *ngIf="allSelectedFiles.length > 1" src="../../../../assets/svg/files-icon.svg" />
      }
    </div>
  </div>


  <label for="element" *ngIf="!uploaded">
    <div class="button-container">
      <div class="custom-input-file">
        <img class="photo-icon" *ngIf="!upload" src="../../../../assets/svg/picture.svg" alt="">
        <input (change)="onFileSelected($event)" (click)="clearFile($event)" id="element" type="file" multiple
          accept="image/*, application/pdf,application/vnd.ms-excel" formControlName="element" class="input-file">
        <p class="button-not-upload" *ngIf="!upload">{{buttonText}}</p>
      </div>
    </div>
  </label>


  <!-- BOTÓN DE SUBIR -->
  <div *ngIf="upload" class="buttons-container">
    <div class="name" *ngIf="upload && allSelectedFiles.length > 0">
      <div *ngFor="let item of allSelectedFiles">
        <p class="title">{{item.name}}</p>
        <p class="size" *ngIf="allSelectedFiles.length ==1">{{item.size / 1000}} kb</p>
      </div>
    </div>
    @if(!hideUploadButton) {
      <div class="actions">
        @if(!uploaded){
        <p class="action-button upload-y" *ngIf="upload && !uploaded" [hidden]="hideUploadButton" (click)="onSubmit()">
          @if(!_uploading){
          <img src="../../../../assets/svg/upload-blue.svg" alt="subir">
          }@else {
          <mat-spinner class="mat-spinner"></mat-spinner>
          }
        </p>
        <p class="action-button delete" *ngIf="upload && !error" [hidden]="hideUploadButton" (click)="deleteFile()"><img
            src="../../../../assets/svg/red-delete.svg" alt="borrar"></p>
        <p class="action-button error" *ngIf="upload && error"><img
            src="../../../../assets/svg/close.svg.svg" alt="error"></p>
        } @else {
        <p class="action-button check"><img src="../../../../assets/svg/check-alert.svg" alt="subido correctamente"></p>
        }
      </div>
    }

  </div>
</form>