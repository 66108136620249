import { Component, Input } from '@angular/core';
import { EnabledLinksUser } from 'src/app/core/models/Affiliate/headerLinks';
import { LinkListModel } from 'src/app/core/models/layout/sidenav-model';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrl: './sidenav.component.scss',
    standalone: false
})
export class SidenavComponent {
  public linkList: LinkListModel[];
  @Input() linksEnabled?: EnabledLinksUser;


  ngOnChanges() {
    const userType = localStorage.getItem('userType');
    if (userType === 'afiliado' && this.linksEnabled) {
      const enabledLinks =this.linksEnabled;
      this.linkList = [
        {
          icon: '../../../../assets/svg/home.svg',
          title: 'Panel de control',
          route: '/afiliado',
          active: true,
        },
        {
          icon: '../../../../assets/svg/simulator.svg',
          title: 'Simulador',
          route: '/afiliado/simulador',
          active: enabledLinks.simulador,
        },
        {
          icon: '../../../../assets/svg/transfer.svg',
          title: 'Transferencias',
          route: '/afiliado/transferencias',
          active: enabledLinks.transfers,
        },
        {
          icon: '../../../../assets/svg/seller.svg',
          title: 'Vendedores',
          route: '/afiliado/usuarios',
          active: enabledLinks.usuarios,
        },
        {
          icon: '../../../../assets/svg/links.svg',
          title: 'Enlaces',
          route: '/afiliado/enlaces',
          active: enabledLinks.links,
        },
        {
          icon: '../../../../assets/svg/inform.svg',
          title: 'Informes',
          route: '/afiliado/reports',
          active: enabledLinks.reports,
        },
      ];
    } else if (userType === 'prestatario' && this.linksEnabled) {
      const enabledLinks = this.linksEnabled;
      this.linkList = [
        {
          icon: '../../../../assets/svg/home.svg',
          title: 'Detalles',
          route: '/prestatario',
          queryParam: {param: this.linksEnabled.creditId},
          active: true,
        },
        {
          icon: '../../../../assets/svg/home.svg',
          title: 'Amortizar',
          route: `/prestatario/simularAmortizacion/${enabledLinks.creditId}`,
          active: !enabledLinks.latePayments,
        },
        {
          icon: '../../../../assets/svg/home.svg',
          title: 'Pagar cuotas',
          route: `/prestatario/pagarCuotasRetrasadas/${enabledLinks.creditId}`,
          active: enabledLinks.latePayments,
        },
        {
          icon: '../../../../assets/svg/home.svg',
          title: 'Medios de pago',
          route: '/prestatario/paymentMethods',
          active: true,
        },
      ];
    }
  }
}
