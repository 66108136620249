<div class="sidenav">
    <div class="linkList">
        @if(linkList){
        @for (item of linkList; track $index) {
        @if (item.active) {
        <div class="item" routerLink="{{item.route}}" [queryParams]="item.queryParam ? item.queryParam : null " routerLinkActive="activeRoute"
            [routerLinkActiveOptions]="{exact: true}">
            <div class="icon">
                <img src="{{item.icon}}" alt="">
            </div>
            <h3>{{item.title}}</h3>
        </div>
        }
        }
        }@else {
        <ngx-skeleton-loader 
        count="4" 
        appearance="line" 
        [theme]="{'height': '74px', 'margin':'3px 0'}">
        </ngx-skeleton-loader>
        }

    </div>
</div>