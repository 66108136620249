<div 
[ngClass]="{
    'alert_toolbar-ok': status == 'ok',
    'alert_toolbar-ko':status == 'ko',
    'alert_toolbar-warning':status == 'warning',
    'alert_toolbar-info':status == 'info'
}"
[@visibleHidden]=" isVisible? 'visible' : 'hidden'">
<div class="title">
  <img *ngIf="status == 'ok'" src="../../../../assets/svg/check-alert.svg" alt="">
  <img *ngIf="status == 'ko'" src="../../../../assets/svg/alert-black.svg" alt="">
  <mat-icon *ngIf="status == 'warning'">warning_amber</mat-icon>
  <mat-icon *ngIf="status == 'info'">info</mat-icon>
  <p>{{alertMessage}}</p>
</div>
      <mat-icon (click)="closeToolbar()">close</mat-icon>

</div>
