import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsersModel, AffiliateModel, BusinessModel } from 'src/app/core';
import { allRegex } from 'src/app/core/extensions/regex';
import { toDateES } from 'src/app/core/extensions/string.extensions';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
    selector: 'app-user-preferences',
    templateUrl: './user-preferences.component.html',
    styleUrl: './user-preferences.component.scss',
    standalone: false
})
export class UserPreferencesComponent {
  public userData: UsersModel;
  public affiliateData: AffiliateModel;
  public businessData: BusinessModel;
  public checkBoxForm: FormGroup;
  public ibanForm: FormGroup;
  public submitted: boolean = false
  public userType: string;
  public toDate = toDateES;
  public idBorrower : string;
  public uploading: boolean = false;
  public file: string;
  loader = true
  logoPath :string
  totalCount = 9;
  uploaded: boolean = false
  public errorMessage : string
  public errorAlert : boolean = false
  public status : string 
  public userId: string;
  public userAccount:any;
  public emailToLogin: any;


  constructor( 
    private formBuilder: FormBuilder, 
    private affiliateService: AffiliateService, 
    private userService: UserService,
    private router: Router,
    private spinnerService: SpinnerService
    ) {
      
    this.ibanForm = this.formBuilder.group({
      iban: ["",[Validators.required, Validators.pattern(allRegex.regexIban)]]
    })
   }

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId')
    this.spinnerService.emitStatus(true)
    this.idBorrower = localStorage.getItem('idBorrower')
    this.emailToLogin = { email: localStorage.getItem('email') }
    this.userType = localStorage.getItem('userType')
    this.userService.getUserByEmail(this.emailToLogin).subscribe(
      res => {
        this.userData = res["hydra:member"][0];
      }
    );



    this.affiliateService.getAffiliate(localStorage.getItem('affiliateId')).subscribe(
      res => {
        this.affiliateData = res;
        this.userService.getUserAccount(this.affiliateData.account).subscribe(
          data =>{
            this.userAccount = data;
          }
        )
        this.affiliateService.businessData(localStorage.getItem('businessId')).subscribe(
          data => {
            this.businessData = data;
            this.logoPath = `https://dev.zank.com.es/logos/${this.businessData.logo}`;
            this.loader = false;
            this.spinnerService.emitStatus(false);
          }
        )
      }
    );
  }


  resetPassword(){
    this.userService.resetPasswordEmail(this.userData.email).subscribe({
      complete: () => {
        this.router.navigate(['resetting/check-email'] , { queryParams: { email: this.userData.email }})
      },
      error: (err) => {
        /* console.log(err.error) */
        if (err.error.error == 'Reset already requested.'){
          this.errorMessage = 'Ya se ha solicitado un reestablecimiento de contraseña con este email.'
        }else if (err.error.error == 'User not exists.'){
          this. errorMessage = 'Este usuario no existe.'
        }
        this.status = 'ko'
        this.errorAlert = true
      },
      next: (res) => {
      }
    })
  }
  closeToolbar(){
    this.errorAlert = false
  }
  public onSubmit(): void {
    this.submitted = true;
    if(this.checkBoxForm.valid){
      const data = {
        checkOne: this.checkBoxForm.get('checkOne')?.value,
        checkTwo: this.checkBoxForm.get('checkTwo')?.value,
        checkThree: this.checkBoxForm.get('checkThree')?.value,
        checkFour: this.checkBoxForm.get('checkFour')?.value,
      }
      this.submitted = false;
      this.checkBoxForm.reset();
    }
  }

  public onSubmitTwo(): void {
    this.submitted = true;
    if(this.ibanForm.valid){
      const data = {
        iban: this.ibanForm.get('iban')?.value,
      }
      this.submitted = false;
      this.ibanForm.reset();
    }
  }

  receiveFile($event: string){
    this.file = $event;
    this.uploading = true;
  }
  
  

}
