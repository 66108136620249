import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dropdown-content',
    templateUrl: './dropdown-content.component.html',
    styleUrl: './dropdown-content.component.scss',
    standalone: false
})
export class DropdownContentComponent {
  @Input() contentType: string;

  hideMenu() {
    this.contentType = ''
  }
}
