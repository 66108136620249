import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactService } from 'src/app/core/services/contact/contact.service';
import { RecaptchaService } from 'src/app/core/services/recaptcha/recaptcha.service';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    standalone: false
})
export class HelpComponent implements OnInit {
  public helpForm: FormGroup;
  public submitted: boolean = false;
  public contador = 500
  public statusBox!: any[]
  public salesAgent: any;
  public fullName: string;
  public loader: boolean = true;
  userType: string;
  status: string;
  alertMessage: string;
  reCAPTCHAToken: any;
  tokenVisible: boolean = false;
  spinner: boolean = false
  public userName: string;
  public userLastName: string

  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  constructor(
    private formbuilder: FormBuilder,
    private affiliateService: AffiliateService,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private recaptchaService: RecaptchaService,
    private contactService: ContactService) {
    this.helpForm = this.formbuilder.group({
      text: ["", [Validators.required, Validators.maxLength(500), Validators.minLength(10)]],
    })
    this.statusBox = [
      {
        statusTitle: "Teléfono",
        text: "Has iniciado la solicitud y tu cliente tiene que validar su teléfono mediante el sms que le hemos enviado a su teléfono móvil",
        color: "#1BD6FF"
      },
      {
        statusTitle: "Formulario",
        text: "Paso donde se completan los datos necesarios del cliente para la solicitud",
        color: "#1BD6FF"
      },
      {
        statusTitle: "Ver.Bancaria",
        text: "Es necesario realizar una verificación mediante open banking o bien mediante documentación",
        color: "#1BD6FF"
      },
      {
        statusTitle: "Análisis",
        text: "Estamos analizando los datos recibidos para darte una contestación lo más rápida posible",
        color: "#1BD6FF"
      },
      {
        statusTitle: "Firma",
        text: "Tu cliente puede acceder a la firma del contrato de préstamo",
        color: "#007AFF"
      },
      {
        statusTitle: "Dni",
        text: "Necesitamos que subas el DNI o NIE del cliente",
        color: "#007AFF"
      },
      {
        statusTitle: "Tarjeta",
        text: "Debes aportar el medio de pago que utilizaremos para cobrar las cuotas",
        color: "#007AFF"
      },
      {
        statusTitle: "Ver.Ident",
        text: "Estamos verificando la identidad de tu cliente",
        color: "#007AFF"
      },
      {
        statusTitle: "Pdt.Transfer",
        text: "La transferencia está lista para ser enviada",
        color: "#34C759"
      },
      {
        statusTitle: "Aprobada",
        text: "Todo esta correcto, el contrato de financiación esta firmado y te hemos transferido el dinero. Podrás comprobar la transferencia en la pestaña de Transferencias",
        color: "#34C759"
      },
      {
        statusTitle: "Denegada",
        text: "El perfil de solvencia de esta operación no permite su aprobación",
        color: "#FF3B30"
      },
      {
        statusTitle: "Cancelada",
        text: "O bien tú o tu cliente nos habéis pedido cancelar la financiación o se ha cancelado automáticamente tras 15 días sin actualizaciones",
        color: '#a8a8a8'
      },
      {
        statusTitle: "En estudio",
        text: "Necesitamos realizar algunas comprobaciones sobre la financiación",
        color: "#FF9500"
      },

    ]

  }

  ngOnInit(): void {
    this.spinnerService.emitStatus(true)
    this.userType = localStorage.getItem('userType')
    if (this.userType !== 'prestatario') {
      this.affiliateService.getSalesAgent(localStorage.getItem('salesAgentId')).subscribe(
        res => {
          const userSalesAgent = res.user?.split("/")
          const pathUser = userSalesAgent?.[userSalesAgent?.length - 1];
          this.userService.getUser(pathUser).subscribe(
            res => {
              this.salesAgent = res;
              this.fullName = `${res.firstname} ${res.lastname}`;
              this.userName = res.firstname;
              this.userLastName = res.lastname;
              this.loader = false;
              this.spinnerService.emitStatus(false)
            }
          )
        }
      )
    }

  }
  public onSubmit(): void {
    this.status = null
    this.spinner = true
    this.reCAPTCHAToken = this.recaptchaService.execute('registerCustomer').then((token) => {
      this.reCAPTCHAToken = token;
      this.tokenVisible = true;
      if (this.tokenVisible) {
        const contactData = {
          type: 'zendesk',
          subject: 'question',
          email: localStorage.getItem('email'),
          text: this.helpForm.get('text')?.value,
          gtoken: this.reCAPTCHAToken,
          name: this.userLastName,
          lastname: this.userLastName,
        }
        this.formDirective.resetForm();
        this.contactService.contactForm(contactData).subscribe({

          complete: () => {
            this.spinner = false
            this.status = 'ok'
            this.alertMessage = 'Tu solicitud se envió correctamente.'

          },
          error: (err) => {
            this.spinner = false
            this.status = 'ko'
            this.alertMessage = 'Hubo un problema con tu petición.'
          },
          next: (res) => {
          }
        })
      }
    })
  }

  onKey(event) {
    this.contador = 500 - event.target.value.length
  }


}
