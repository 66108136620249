import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  private siteKey: string = environment.recaptcha.siteKey;
  constructor() {}

  execute(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!(window as any).grecaptcha) {
        reject('reCAPTCHA not loaded');
        return;
      }
      (window as any).grecaptcha.ready(() => {
        (window as any).grecaptcha
          .execute(this.siteKey, { action })
          .then((token: string) => {
            resolve(token);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    });
  }
}
