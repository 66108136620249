import { Component, Input, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-logos-carousel',
  templateUrl: './logos-carousel.component.html',
  styleUrls: ['./logos-carousel.component.scss'],
  standalone: false
})
export class LogosCarouselComponent {
  isMobile: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }

  ngOnInit() {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    this.isMobile = window.innerWidth <= 1445; 
  }
  logos: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 2000,
    autoplay: true,
    autoplayTimeout: 2000,
    slideTransition: 'linear',
    animateOut: 'slideOutLeft',
    animateIn: 'slideInRight',
    responsive: {
      0: {
        items: 2,
        autoplay: true
      },
      480: {
        items: 3,
        autoplay: true
      },
      740: {
        items: 4,
        autoplay: true
      },
      940: {
        items: 5,
        autoplay: true
      },
      1445: {
        items: 6,
        autoplay: true,
      },

    },
    nav: false
  };

  public items: any = [
    {
      img: '../../../assets/img/img-landing/american-express.png',
    },
    {
      img: '../../../assets/img/img-landing/santander-logo.svg',
    },
    {
      img: '../../../assets/img/img-landing/mediapro.png',
    },
    {
      img: '../../../assets/img/img-landing/Seаt-Logo.png',
    },
    {
      img: '../../../assets/img/img-landing/american-express.png',
    },
    {
      img: '../../../assets/img/img-landing/santander-logo.svg',
    },
    {
      img: '../../../assets/img/img-landing/mediapro.png',
    },
  ];

}