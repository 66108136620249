import { Component, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserDataService } from 'src/app/core/services/user/userData.service';

@Component({
  selector: 'app-header-private-desktop',
  templateUrl: './header-private-desktop.component.html',
  styleUrl: './header-private-desktop.component.scss',
  standalone: false,
})
export class HeaderPrivateDesktopComponent {
  public deploy: boolean;
  public userType: string;
  public name: string;

  constructor(
    private userDataService: UserDataService,
    private auth: AuthService,
    private eRef: ElementRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.userType = localStorage.getItem('userType')
    this.userDataService.getNameSession().subscribe((res) => {
      this.name = res;
    });
  }

  toggleDeploy() {
    this.deploy = !this.deploy;
  }

  logout() {
    this.auth.logout();
  }
navigateTo(path:string) {
  this.router.navigate([`${this.userType}/${path}`])
}
  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.deploy = false;
    }
  }
}
