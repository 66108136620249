import { Component, ElementRef, HostListener } from '@angular/core';
import { LinkListModel } from 'src/app/core/models/layout/sidenav-model';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserDataService } from 'src/app/core/services/user/userData.service';
import { EnabledLinksUser } from 'src/app/core/models/Affiliate/headerLinks';
import { fadeInLeftOnEnterAnimation, fadeOutLeftOnLeaveAnimation } from 'angular-animations';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusBorrowerService } from 'src/app/core/services/borrower/status-borrower.service';
import { getFase } from 'src/app/core/extensions/status.extensions';

@Component({
  selector: 'app-header-private-burger',
  templateUrl: './header-private-burger.component.html',
  styleUrl: './header-private-burger.component.scss',
  standalone: false,
  animations: [
    fadeInLeftOnEnterAnimation({ duration: 400 }),
    fadeOutLeftOnLeaveAnimation(),
  ],
})
export class HeaderPrivateBurgerComponent {
  public deploy: boolean;
  public userType: string;
  public name: string;
  public linksEnabled: EnabledLinksUser;
  public linkList: LinkListModel[];
  public personalLinkList: LinkListModel[];
  public isOpenBurger: boolean;
  public isChecked: boolean = false;
  public idBorrower: string;
  public isEAM!: boolean;

  constructor(
    private userDataService: UserDataService,
    private auth: AuthService,
    private eRef: ElementRef,
    private route: ActivatedRoute,
    private statusBorrower: StatusBorrowerService
  ) { }

  ngOnInit() {
    this.userDataService.getNameSession().subscribe((res) => {
      this.name = res;
      this.userType = localStorage.getItem('userType');
      this.linksEnabled = JSON.parse(localStorage.getItem('linksEnabled'))
      if(this.linksEnabled) {
        this.links(this.userType)
      }
    });
  }

  toggleDeploy() {
    this.deploy = !this.deploy;
  }

  showMenu() {
    this.isOpenBurger = true;
  }

  hideMenu() {
    this.isOpenBurger = false;
  }

  togglePosition() {
    this.isChecked = !this.isChecked;
  }

  ngOnChanges() {
    this.links(this.userType);
  }

  links(userType) {
    if (userType === 'afiliado') {
      this.linkList = [
        {
          icon: '../../../../assets/svg/home-white.svg',
          title: 'Panel de control',
          route: '/afiliado',
          active: true,
        },
        {
          icon: '../../../../assets/svg/simulator-white.svg',
          title: 'Simulador',
          route: '/afiliado/simulador',
          active: this.linksEnabled.simulador,
        },
        {
          icon: '../../../../assets/svg/transfer-white.svg',
          title: 'Transferencias',
          route: '/afiliado/transferencias',
          active: this.linksEnabled.transfers
        },
        {
          icon: '../../../../assets/svg/seller-white.svg',
          title: 'Vendedores',
          route: '/afiliado/usuarios',
          active: this.linksEnabled.usuarios
        },
        {
          icon: '../../../../assets/svg/links-white.svg',
          title: 'Enlaces',
          route: '/afiliado/enlaces',
          active: this.linksEnabled.links
        },
        {
          icon: '../../../../assets/svg/inform-white.svg',
          title: 'Informes',
          route: '/afiliado/reports',
          active: this.linksEnabled.reports
        },
      ];
      this.personalLinkList = [
        {
          icon: '../../../../assets/svg/document.svg',
          title: 'Documentos',
          route: '/documentos',
        },
        {
          icon: '../../../../assets/svg/ask.svg',
          title: 'Ayúdanos',
          route: '/afiliado/ayuda',
        },
        {
          icon: '../../../../assets/svg/settings.svg',
          title: 'Preferencias',
          route: '/afiliado/preferencias',
        },
      ];
    } else {
      this.personalLinkList = [
        {
          icon: '../../../../assets/svg/document.svg',
          title: 'Documentos',
          route: '/documentos',
        },
        {
          icon: '../../../../assets/svg/ask.svg',
          title: 'Ayúdanos',
          route: '/prestatario/ayuda',
        },
        {
          icon: '../../../../assets/svg/settings.svg',
          title: 'Preferencias',
          route: '/prestatario/preferencias',
        },
      ];
      this.route.queryParams.subscribe(params => {
        if (params['id']) {
          this.statusBorrower.checkStatus().subscribe(res => {
            this.isEAM = getFase(res.loanStatus) === 'FA';
            this.idBorrower = localStorage.getItem('idBorrower')
            const hasLatePayments = localStorage.getItem('latePayments');
            if(this.isEAM){
              this.linkList = [
                {
                  icon: '../../../../assets/svg/home-white.svg',
                  title: 'Detalles',
                  route: '/prestatario',
                  active: true
                },
                {
                  icon: '../../../../assets/svg/transfer-white.svg',
                  title: 'Pagar cuotas',
                  route: `/prestatario/pagarCuotasRetrasadas/${this.idBorrower}`,
                  active: JSON.parse(hasLatePayments)
                },
                {
                  icon: '../../../../assets/svg/transfer-white.svg',
                  title: 'Amortizar',
                  route: `/prestatario/simularAmortizacion/${this.idBorrower}`,
                  active: !JSON.parse(hasLatePayments)
        
                },
                {
                  icon: '../../../../assets/svg/simulator-white.svg',
                  title: 'Medios de pago',
                  route: '/prestatario/paymentMethods',
                  active: true
                },
              ];
            }
})
        }
      })

    }
 
  }

  logout() {
    this.auth.logout();
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.deploy = false;
    }
  }
  ngOnDestroy(){
    this.statusBorrower.subUnsubscribe()
  }
}
