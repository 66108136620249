<div 
[ngClass]="{
  'single_container': !ibanCHB,
  'single_cont':ibanCHB
}">
    <div class="title_header">
      <h4>Cuenta bancaria</h4>
        <mat-icon matTooltip="Tu cuenta bancaria en formato IBAN: ES...">info</mat-icon>
    </div>
    <p class="description">Introduce el número de cuenta en formato IBAN donde deseas recibir los pagos. A continuación deberás subir un justificante de tu IBAN.</p>
    <div class="title_header_iban">
      <h4 class="iban">IBAN</h4>
    </div>
    <form [formGroup]="ibanForm" novalidate (ngSubmit)="onSubmit()" class="iban_form">
      <mat-form-field appearance="fill" color="primary" class="long-input">
        <mat-label>ES</mat-label>
        <input matInput formControlName="iban" #iban>
        <mat-icon matSuffix *ngIf="ibanForm.valid">check</mat-icon>
        <mat-error *ngIf="!ibanForm.get('iban')?.valid && (ibanForm.get('iban')?.dirty || submitted)">Introduzca un IBAN válido</mat-error>
      </mat-form-field>
      <button class="main-color-button" (click)="onSubmit()" [disabled]="!ibanForm.valid" *ngIf="!ibanCHB">
        <div class="into_btn">Guardar Iban<mat-spinner [diameter]="20" *ngIf="progressBar"></mat-spinner></div>
      </button>
    </form>
  </div>

