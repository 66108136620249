import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor{
  public error: any;
  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
         /*  if(httpErrorResponse.url?.includes('/checkStatus')){
            this.statusBorrower.sub.unsubscribe();
            return throwError(httpErrorResponse)
          } */
          if(httpErrorResponse.status === 401 || httpErrorResponse.status === 403) {
            if(!httpErrorResponse.url?.includes('/login')) {
              this.authService.logout();
              this.router.navigate(['/login']);
              this.error = httpErrorResponse
       
               return throwError(httpErrorResponse)
            } else {
              return throwError(httpErrorResponse);
            }            
          } else {
          return throwError(httpErrorResponse);
          } 
        })
      )
  }
}
