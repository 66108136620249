import { ErrorInterceptorService } from './../../../core/interceptors/error-interceptor.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { getFase } from 'src/app/core/extensions/status.extensions';
import { UserDataService } from 'src/app/core/services/user/userData.service';
import { concatMap, of } from 'rxjs';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
    standalone: false
})
export class LoginFormComponent implements OnInit {
  public registerForm!: FormGroup;
  public submitted: boolean = false;
  public _hideNav: boolean
  public userType: string;
  public affiliatePath: string[];
  public creditList: any[];
  public activeCredit: any;
  public error: any;
  public userName: string;
  public emailToLogin: any;
  public activeCreditList: any[]
  @Input() hiddenText: boolean = false;
  @Input() set hiddenNav(val: boolean) {
    this._hideNav = val
  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private affiliateService: AffiliateService,
    public SpinnerService: SpinnerService,
    private errorService: ErrorInterceptorService,
    private userDataService: UserDataService
  ) {

  }

  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      _username: ["", [Validators.required, Validators.email]],
      _password: ["", [Validators.required, /* Validators.pattern(allRegex.regexPassword) */]]
    })
  }


  public onSubmit(): void {
    const formData = new FormData();
    this.submitted = true;
    if (this.registerForm.valid) {
      formData.append('_username', this.registerForm.get('_username').value),
        formData.append('_password', this.registerForm.get('_password').value),
        this.authService.signIn(formData).subscribe(
          data => {
            this.emailToLogin = { email: this.registerForm.get('_username').value }
            localStorage.setItem('email', this.registerForm.get('_username').value)
            this.submitted = false;
            this.setSession(data.token);
            this.userData(this.emailToLogin);
          },
          err => {
            if (err.error.message == "Invalid credentials.") {
              this.error = 'Usuario y/o contraseña no válidos.'
              /* this.registerForm.get('_username').setErrors({credendialsError:'Usuario y/o contraseña no válidos.'})
              this.registerForm.get('_password').setErrors({credendialsError:'Usuario y/o contraseña no válidos.'}) */
            }

          }
        )
    }
  }

  public getIdFromPath(path: string): string {

    const segments = path?.split("/");
    return segments?.[segments.length - 1];
  }

  userData(email) {
    this.userDataService.setUserType('');
    this.userDataService.setNameSession('');

    this.userService.getUserByEmail(email).pipe(
      concatMap((res) => {
        const userData = res["hydra:member"][0]
        localStorage.setItem('userType', userData.tipo)
        this.userDataService.setUserType(userData.tipo)
        const userId = userData.id;
        localStorage.setItem('userId', userId);
        localStorage.setItem('userName', userData.firstname)

        this.userType = localStorage.getItem('userType')
        if (this.userType === 'prestatario') {
          this.userDataService.setNameSession(userData.firstname);
          if (userData.afiliado) {
            localStorage.setItem('affiliateId', this.getIdFromPath(userData.afiliado));
            return this.userService.getCreditList(userId)

          } else {
            this.router.navigate(['/prestatario/inactivo'])
            return of(null);
          }
        } else {
          localStorage.setItem('affiliateId', this.getIdFromPath(userData.afiliado));
          return of(null);
        }
      }),
      concatMap((creditList) => {
        if (creditList) {
          this.creditList = creditList["hydra:member"];
          const activePhases = ['FC', 'FA', 'FS', 'FF','FE'];
          const activeStatus = ['EAM', 'RET', 'FAL']
          /*    this.activeCredit = this.creditList.find(item => activePhases.includes(getFase(item.estadoCd))); */

          //!listado de créditos ACTIVOS (preguntar si tienen que ser activos o cualquiera)

          this.activeCreditList = this.creditList.filter(item => activePhases.includes(getFase(item.estadoCd)) || activeStatus.includes(item.estadoCd));
        }
        return this.affiliateService.getAffiliate(localStorage.getItem('affiliateId'))
      })
    ).subscribe((data) => {
      localStorage.setItem('financedRate', data.financedRate)
      localStorage.setItem('formacionAsnef', data.formacionAsnef)
      if (this.userType != 'prestatario') {
        this.userDataService.setNameSession(data.corporationName)
      }
      const businessId = this.getIdFromPath(data!.datosEmpresa)
      this.affiliateService.businessData(businessId).subscribe(res => {
        localStorage.setItem('transfers', JSON.stringify(res.transactionsEnabled))
      })
      localStorage.setItem('businessId', this.getIdFromPath(data.datosEmpresa));
      localStorage.setItem('estadoCd', data.estadoCd);
      localStorage.setItem('corporationName', data.corporationName);
      localStorage.setItem('datosEmpresa', data.datosEmpresa);
      localStorage.setItem('associatedInvestor', this.getIdFromPath(data.associatedInvestor));
      localStorage.setItem('salesAgentId', this.getIdFromPath(data.zankSalesAgent));
      localStorage.setItem('inversores', data.usuarios)
      localStorage.setItem('cobrosEnabled', data.cobrosEnabled);
      localStorage.setItem('reports', data.reports);
      localStorage.setItem('links', data.links);
      localStorage.setItem('simulador', data.simulador);
      localStorage.setItem('usuarios', data.usuarios);

      if (this.userType == 'prestatario') {
        /*  if (this.activeCredit) {
           this.router.navigate(['/prestatario'], { queryParams: { id: this.activeCredit.id } });
         } else {
           this.router.navigate(['/prestatario/inactivo']);
         } */
        if (this.activeCreditList.length === 1) {
          this.router.navigate(['/prestatario'], { queryParams: { id: this.activeCreditList[0].id } });
        } else if (this.activeCreditList.length > 1) {
          this.router.navigate(['/prestatario']);
        } else if (this.activeCreditList.length === 0) {
          this.router.navigate(['/prestatario/inactivo']);
        }
      } else {
        this.router.navigate(['/afiliado']);
      }
    }, err => {
      /* console.log(err) */
    });

  }

  setSession(token: string) {
    if (token != '')
      this.authService.setSessionLogged(token);
    this.authService.timeOutToRefresh();
  }

  logout() {
    return this.authService.logout();
  }

  outToken() {
    return this.logout();
  }

}
