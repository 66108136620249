import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    standalone: false
})
export class FaqComponent implements OnInit {
  public panelOpenState = false;
  @Input() statusBox!: any[];

  constructor() { }

  ngOnInit(): void {}

}
