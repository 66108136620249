<div class="dark-header header-fixed">
  <div class="left-content-burger">
    <img class="logo" src="../../../../assets/logo-white.png" alt="logo-confia" routerLink="/"/>
  </div>
  <div class="right-content-burger">
    <div class="toggle-dark-mode">
      <div class="btn">
        <input
          type="checkbox"
          name="check"
          id="check"
          (click)="togglePosition()"
        />
        <label for="check">
          <!-- <div class="box" [ngClass]="{ 'box-end': isChecked }">
            <div class="ball"></div>
            <div class="scenary">
              <div class="moon">
                <img src="../../../../assets/svg/moon.png" alt="" />
              </div>
              <div class="sun">
                <img src="../../../../assets/svg/Vector.svg" alt="" />
              </div>
            </div>
          </div> -->
        </label>
      </div>
    </div>
    <div class="non-deployable">
      <p class="name">{{ name.charAt(0) }}</p>
    </div>
    <div class="burger-menu">
      <img
        *ngIf="!isOpenBurger"
        (click)="showMenu()"
        src="../../../../assets/svg/menu.svg"
        alt="Menu"
      />
      <img
        *ngIf="isOpenBurger"
        (click)="hideMenu()"
        src="../../../../assets/svg/close.svg"
        alt="Menu"
      />
    </div> 
  </div>
  <div
    *ngIf="isOpenBurger"
    class="burger-links"
    [@fadeInLeftOnEnter]
    [@fadeOutLeftOnLeave]
  >
    <p *ngIf="linkList">Plataforma</p>
    @for(item of linkList; track $index){
      @if(item.active) {
        <div class="burger-item" routerLink="{{ item.route }}" (click)="hideMenu()">
          <div
            class="link"
            routerLinkActive="activeRoute"
          >
            <div class="icon">
              <img src="{{ item.icon }}" alt="" />
            </div>
            <h3>{{ item.title }}</h3>
          </div>
        </div>
      }
    }

    <p>Perfil de usuario</p>
    <div *ngFor="let item of personalLinkList" class="burger-item" routerLink="{{ item.route }}" (click)="hideMenu()">
      <div
        class="link"
        routerLinkActive="activeRoute"
      >
        <div class="icon">
          <img src="{{ item.icon }}" alt="" />
        </div>
        <h3>{{ item.title }}</h3>
      </div>
    </div>
    <div class="burger-item">
      <div class="link" (click)="logout()">
        <div class="icon">
          <img src="../../../../assets/svg/logout.svg" alt="" />
        </div>
        <h3>Logout</h3>
      </div>
    </div>
  </div>
</div>