import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderDropdownService {

  private _isBlueTheme = false;
  private _showDropdownParticular = false;
  private _showDropdownBusiness = false;
  private _showDropdownUs = false;
  private _showDropdownLogin = false;

  constructor() { 
    document.addEventListener('click', this.handleClickOutside.bind(this));
  }

  get isBlueTheme() {
    return this._isBlueTheme;
  }

  set isBlueTheme(value: boolean) {
    this._isBlueTheme = value;
  }

  get showDropdownParticular() {
    return this._showDropdownParticular;
  }

  toggleDropdownParticular(event: MouseEvent) {
    event.stopPropagation(); // Evitar el cierre inmediato al hacer clic dentro del dropdown
    this._showDropdownParticular = !this._showDropdownParticular;
    this.closeOtherDropdowns('particular');
  }

  get showDropdownBusiness() {
    return this._showDropdownBusiness;
  }

  toggleDropdownBusiness(event: MouseEvent) {
    event.stopPropagation();
    this._showDropdownBusiness = !this._showDropdownBusiness;
    this.closeOtherDropdowns('business');
  }

  get showDropdownUs() {
    return this._showDropdownUs;
  }

  private handleClickOutside(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown-container')) {
      this.closeAllDropdowns();
    }
  }

  toggleDropdownUs(event: MouseEvent) {
    event.stopPropagation();
    this._showDropdownUs = !this._showDropdownUs;
    this.closeOtherDropdowns('us');
  }

  get showDropdownLogin() {
    return this._showDropdownLogin;
  }

  toggleDropdownLogin(event: MouseEvent) {
    event.stopPropagation();
    this._showDropdownLogin = !this._showDropdownLogin;
    this.closeOtherDropdowns('login');
  }

  // Método para cerrar los otros dropdowns
  private closeOtherDropdowns(activeDropdown: string) {
    if (activeDropdown !== 'particular') this._showDropdownParticular = false;
    if (activeDropdown !== 'business') this._showDropdownBusiness = false;
    if (activeDropdown !== 'us') this._showDropdownUs = false;
    if (activeDropdown !== 'login') this._showDropdownLogin = false;
  }

  // Método para cerrar todos los dropdowns
  closeAllDropdowns() {
    this._showDropdownParticular = false;
    this._showDropdownBusiness = false;
    this._showDropdownUs = false;
    this._showDropdownLogin = false;
  }
}
