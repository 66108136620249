import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { SpinnerDialogComponent } from "src/app/shared/layout/spinner/spinner-dialog/spinner-dialog.component";
import { SpinnerComponent } from "src/app/shared/layout/spinner/spinner.component";



@Injectable({
  providedIn: 'root',
})

export class SpinnerService {
  public dialogRef: MatDialogRef<SpinnerDialogComponent>
  public skeletonLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public preventClose$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  constructor(private dialog: MatDialog) {

  }

  public loadingStatus = new Subject();
  _loading = false;

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
    this.loadingStatus.next(value);
  }
  public skeletonStatus(): Observable<boolean> {
    return this.skeletonLoading$.asObservable();
  }
  public emitStatus(status: boolean): void {
    this.skeletonLoading$.next(status);

  }

  showLoading() {
    if (this.dialogRef) {
      this.dialogRef.close()
    }
    this.loading = true;
    this.dialogRef = this.dialog.open(SpinnerDialogComponent, { disableClose: true });
  }

  preventClose() {
    this.preventClose$.next(true)
  }
  allowClose() {
    this.preventClose$.next(false)
  }
  hideLoading() {
    this.loading = false;
    /* this.dialog.closeAll() */
    if (this.dialogRef) {
      this.dialogRef.close()
    }
  }

  start(message?: string): MatDialogRef<SpinnerComponent> {
    const dialogRef = this.dialog.open(SpinnerComponent, {
      disableClose: true,
      data: message == '' || message == undefined ? "Cargando..." : message
    });
    return dialogRef;
  };

  message(message: string, ref?: MatDialogRef<SpinnerComponent>) {
    ref?.componentInstance.sendMessage(message)
  }

  stop(ref?: MatDialogRef<SpinnerComponent>) {
    ref?.close();
  }

}

