<div class="header-container">
    <div class="header-main-contain">
      <nav class="header-nav">
        <ul class="nav-list">
          <a class="nav-item" routerLinkActive="activeRoute" [routerLink]="['/prestatario']" [queryParams]="{ id: this.idBorrower }">
            Detalles
          </a>
          <a
          *ngIf="cuotasRetrasadas == 'false' && !isAMA"
            class="nav-item"
            [routerLink]="['/prestatario/simularAmortizacion', idBorrower]"
            routerLinkActive="activeRoute"
          >
            Amortizar
          </a>
          <a
          *ngIf="cuotasRetrasadas == 'true'"
            class="nav-item"
            [routerLink]="['/prestatario/pagarCuotasRetrasadas', idBorrower]"
            routerLinkActive="activeRoute"
          >
            Pagar cuotas
          </a>
          <a
            class="nav-item"
            [routerLink]="'/prestatario/paymentMethods'"
            routerLinkActive="activeRoute"
          >
            Medios de pago
          </a>
        </ul>
      </nav>
      <mat-divider></mat-divider>
    </div>
  </div>
