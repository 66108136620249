export function toStatus(value: string) {
  switch (value) {
    case 'INI':
      value = 'Iniciada'
      break;
    case 'VTF':
      value = 'Teléfono'
      break;
    case 'CHB':
    case 'CHC':
      value = 'Ver.Bancaria'
      break;
    case 'EST':
      value = 'En estudio'
      break;
    case 'SIG':
      value = 'Firma'
      break;
    case 'BEL':
    case 'BEC':
      value = 'Docs.Auto'
      break;
    case 'DNI':
      value = 'DNI'
      break;
    case 'TAR':
      value = 'Tarjeta'
      break;
    case 'KYC':
    case 'EDO':
      value = 'Apro-docs.'
      break;

    case 'PUB':
    case 'TRP':
      value = 'Pdte.Transfer'
      break;
    case 'SBY':
    case 'FIR':
      value = 'Aprobada/Pdte.Transf'
      break;
    case 'TRE':
      value = 'Transferencia enviada'
      break;
    case 'AMO':
    case 'EAM':
    case 'RET':
    case 'AMA':
    case 'FAL':
    case 'ACC':
    case 'FAC':
      value = 'Trf. enviada'
      break;
    case 'DE2':
    case 'DE1':
    case 'DE3':
    case 'DE4':
      value = 'Denegada'
      break;
    case 'CA0':
    case 'CA1':
    case 'CA2':
    case 'CA3':
    case 'CA4':
      value = 'Cancelada'
      break;
    case 'ANA':
      value = 'Análisis'
      break;
    case 'SIM':
      value = 'Simulador'
      break;
    case 'ASK':
    case 'COT':
      value = 'Formulario'
      break;
    case 'WK2':
    case 'VDO':
      value = 'Ver.Ident'
      break;
    default:
      value = null
      break;
  }
  return value
}

  export function toStatusClass(value: string) {

    switch (value) {
      case 'INI':
        value = 'Iniciada';
        break;
      case 'VTF':
      case 'CHB':
      case 'CHC':
      case 'ASK':
      case 'COT':
      case 'ANA':
        value = 'Azules';
        break;
      case 'EST':
        value = 'En-Estudio';
        break;
      case 'SIG':
      case 'DNI':
      case 'TAR':
      case 'BEL':
      case 'BEC':
      case 'WK2':
      case 'VDO':
        value = 'Verdes';
        break;
      case 'KYC':
      case 'EDO':
        value = 'Apro-docs.';
        break;
      case 'PUB':
      case 'TRP':
      case 'FIR':
      case 'SBY':
      case 'AMO':
      case 'EAM':
      case 'RET':
      case 'AMA':
      case 'FAL':
      case 'ACC':
      case 'FAC':
        value = 'VerdeOscuro';
        break;
      case 'TRE':
        value = 'Transferencia-enviada';
        break;
      case 'DE2':
      case 'DE1':
      case 'DE3':
      case 'DE4':
        value = 'Denegada'
        break;
      case 'CA0':
      case 'CA1':
      case 'CA2':
      case 'CA3':
      case 'CA4':
        value = 'Cancelada'
        break;
/*       case 'ANA':
        value = 'Analisis'
        break; */
      case 'SIM':
        value = 'Simulador'
        break;
      default:
        value = null
    }
    return value
  }




  export function getFase(fase: string) {
    switch (fase) {
      case 'CA0':
      case 'CA1':
      case 'CA2':
      case 'CA3':
      case 'CA4':
      case 'REC':
        fase = 'FC';
        break;
      case 'SIM':
      case 'VTF':
      case 'ASK':
      case 'COT':
      case 'BEL':
      case 'BEC':
      case 'CHB':
      case 'CHC':
      case 'ANA':
      case 'SIG':
      case 'DNI':
      case 'TAR':
        fase = 'FS';
        break;
      case 'VDO':
      case 'EST':
      case 'WK2':
      case 'SBY':
        fase = 'FE';
        break;
      case 'PUB':
      case 'FIR':
        fase = 'FF';
        break;
      case 'EAM':
      case 'AMO':
      case 'AMA':
      case 'ACC':
      case 'FAC':
      case 'RET':
        fase = 'FA';
        break;
      case 'DE1':
      case 'DE2':
      case 'DE3':
      case 'DE4':
      case 'IKO':
      case 'TEX':
      case 'FAL':
        fase = 'FD';
        break;
      default:
        fase = 'UNK';
    }
    return fase;
  }


  export function getColorCuota(color: string) {
    switch (color) {
      case 'PD':
        color = '#EFF0F3';
        break;
      case 'DE':
        color = '#1BD6FF';
        break;
      case 'EC':
        color = '#286983';
        break;
      case 'DV':
        color = '#cec61d';
        break;
      case 'CO':
      case 'CR':
      case 'CJ':
      case 'CZ':
        color = '#34C759';
        break;
      case 'GT':
      case 'CP':
      case 'FR':
      case 'RE':
        color = '#f0ad4e';
        break;
      case 'AN':
        color = '#777';
        break;
      case 'ACT':
        color = '#FF3B30';
        break;
      case 'RF':
        color = '#82b482';
        break;
      case 'FA':
        color = '#d9534f';
        break;
      default:
        color = '#777'
    }
    return color;
  }


  export function toStatusCollection(value: string) {
    if (value == 'AMO' || value == 'AMA') {
      return (value = 'Amortizado');
    } else if (value == 'RET') {
      return (value = 'Retrasado');
    } else if (value == 'EAM' || value == 'VTF' || value == 'FAC') {
      return (value = 'Amortización');
    } else if (value == 'WK2' || value == 'SBY' || value == 'FIR') {
      return (value = 'Completado');
    } else if (value == 'PUB') {
      return (value = 'Publicado');
    } else if (value == 'FAL') {
      return (value = 'Fallido');
    } else if (value == 'FD') {
      return (value = 'Cancelado');
    } else {
      return '??'
    }
  }
  export function getStatusTxt(status: string) {
    switch (status) {
      case "PD":
        return "Pendiente";
        break;
      case "DE":
        return "Devengandose";
        break;
      case "EC":
        return "En cobro";
        break;
      case "DV":
        return "Devuelta";
        break;
      case "CO":
        return "Cobrada";
        break;
      case "AA":
        return "Anticipada";
        break;
      case "AN":
        return "Anulada";
        break;
      case "ACT":
        return "Actualizando";
        break;
      case "RE":
        return "Retrasada";
        break;
      case "RF":
        return "Refinanciada";
        break;
      case "FA":
        return "Fallida";
        break;
      case "GT":
        return "Gestion";
        break;
      case "CR":
        return "Recobrada AG";
        break;
      case "CJ":
        return "Recobrada Judicialmente";
        break;
      case "CZ":
        return "Recobrada Zank";
        break;
      case "FR":
        return "Congelada";
        break;
    }

    return "";
  }


  export function toStatusHistorial(value: string, financing) {
    if ((value == 'SIN') || (value == 'SIM') || (value == 'ASK')) {
      return value = 'Iniciada'
    } else if ((value == 'DNI')) {
      return value = 'DNI'
    } else if ((value == 'CHB' || value == 'ANA' || value == 'CHC')) {
      return value = 'Ver. banco'
    } else if ((value == 'SIG')) {
      return value = 'Pdte. firma'
    } else if (value == 'EST') {
      return value = 'En estudio'
    } else if (((value == 'PUB') || (value == 'WK2') || value == 'SBY')) {
      return value = 'Publicada'
    } else if (value == 'CMP') {
      return value = 'Pdte. firma'
    } else if (value == 'FIR') {
      if (financing.firmasVerificadas == true) {
        return value = 'Pdte. Trans.'
      } else {
        return value = 'Validando'
      }
    } else if (((value == 'EAM') || (value == 'ACC') || (value == 'FAC'))) {
      return value = 'Aprobada'
    } else if ((value == 'DE1') || (value == 'DE2') || (value == 'DE3')) {
      return value = 'Denegada'
    } else if ((value == 'CA1') || (value == 'CA2') || (value == 'CA3') || (value == 'CA4')) {
      return value = 'Cancelada'
    } else {
      return null
    }
  }

  export function toActivateString(value: boolean) {
    if (value == true) {
      return 'Si'
    } else {
      return 'No'
    }
  }

  export function toClassActivate(value: boolean) {
    if (value == true) {
      return 'VerdeOscuro'
    } else {
      return 'Denegada'
    }
  }
