import { SpinnerService } from './../../../core/services/spinner/spinner.service';
import { UserService } from './../../../core/services/user/user.service';
import { AffiliateModel } from './../../../core/models/Affiliate/affiliate';
import { Component, OnInit } from '@angular/core';
import {Router } from '@angular/router';
import { AffiliateService } from 'src/app/core/services/affiliate/affiliate.service';
import { EnabledLinksUser } from 'src/app/core/models/Affiliate/headerLinks';

@Component({
    selector: 'app-header-affiliate',
    templateUrl: './header-affiliate.component.html',
    styleUrls: ['./header-affiliate.component.scss'],
    standalone: false
})
export class HeaderAffiliateComponent implements OnInit {
  public affiliateData: AffiliateModel;
  public linksEnabled: EnabledLinksUser = JSON.parse(localStorage.getItem('linksEnabled'));
  public idBorrower: string
  public userType: string;
  public businessId: string;
  public businessLogo: string
  public investor: string

  constructor(private router: Router, private affiliateService: AffiliateService, private userService: UserService, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.businessId = localStorage.getItem('businessId')
    this.userType = localStorage.getItem('userType')
    this.idBorrower = localStorage.getItem('idBorrower')
    this.investor = localStorage.getItem('inversores');
    this.spinnerService.emitStatus(true)
    this.affiliateService.businessData(this.businessId).subscribe((res)=>{
      this.businessLogo = res.logo
      localStorage.setItem('askEnabled', JSON.stringify(res.ASKEnabled))
      this.spinnerService.emitStatus(false)
    })
    
  }

  navigateTo(idBorrower?: string){
    if(this.userType == 'prestatario'){
      this.router.navigate(['/prestatario'], { queryParams: { id: idBorrower }})
    }else if(this.userType == 'afiliado'){
      this.router.navigate(['afiliado'])
    }
  }

}
