import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  api = environment.baseUrl
  timeOver: boolean = false;

  constructor(
    private http: HttpClient, 
    private router: Router, 
  ) { }

  // Llamadas de login

  signIn(user){ 
    return this.http.post<any>(`${this.api}/api/login_check`, user)
  }
  getToken(id: string, hash: string, belender?:string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/api/tokenFromSMSHash/${id}/${hash}${belender}`)
  }
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return token !== null;
  }
  getTokenFromAdmin(id: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/api/tools/microadmin/tokenFromToken/${id}`);
  }
  refreshToken(token){
    return this.http.put<any>(`${this.api}/api/refreshJWT`, token)
  }

  //Funcionalidad

  getTokenSessionStorage(): Observable<boolean> { 
    return this.token$.asObservable();
  }

  setTokenSessionStorage() {
    this.token$.next(this.checkToken());
  }

  checkToken() { 
    if(localStorage.getItem('token')) {
      return true
    } else {
      return false
    }
  }

  setSessionLogged(authResult: any): void {
    localStorage.setItem('token', authResult);
    this.checkExpiryToken();
    this.setTokenSessionStorage()
  }

  logout(){ 
   localStorage.clear();
   this.setTokenSessionStorage();
   this.router.navigate(["/"]);
  
  }
  
  // Funcionalidad de Refresh Token
 
  timeOutToRefresh(){
    setTimeout(() => {
      this.timeOver = true;
    }, 2700000) //esto va en una variable 2700000 45minutos
  }
  checkExpiryToken(){
    const controlDate = new Date(Date.now())
    const now = controlDate.getTime()
    const ttl = 3600000
    const expiry = now + ttl;
    localStorage.setItem('expiry', JSON.stringify(expiry))
    
  }
}
