<div class="clients-container" *ngIf="contentType === 'particular'">
    <div class="first-content">
        <h3>Soluciones para particulares</h3>
        <div class="div" routerLink="prestamo-de-consumo" (click)="hideMenu()">
            <p class="title">Préstamo de consumo</p>
            <p class="subtitle">Paga tus compras a tu ritmo, con plazos de hasta 10 años.</p>
        </div>
        <div class="div" routerLink="reunificacion-deudas" (click)="hideMenu()">
            <p class="title">Reunificación de deudas</p>
            <p class="subtitle">Unifica todos tus préstamos y paga hasta un 75% menos cada mes.</p>
        </div>
        <div class="div" routerLink="ahorro-inversion" (click)="hideMenu()">
            <p class="title">Ahorro e inversión</p>
            <p class="subtitle">Saca el máximo provecho de tu dinero, hoy y mañana.</p>
        </div>
        <div class="div" routerLink="pagares" (click)="hideMenu()">
            <p class="title">Pagarés</p>
            <p class="subtitle">Obtén el crédito que necesitas y accede a las mejores soluciones de inversión.</p>
        </div>
    </div>
    <div class="second-content">
        <div class="div" routerLink="prestamo-personal" (click)="hideMenu()">
            <p class="title" >Préstamo personal</p>
            <p class="subtitle">Cuéntanos tu proyecto y te ayudaremos a hacerlo realidad.</p>
        </div>
        <div class="div" routerLink="anticipo-alquiler" (click)="hideMenu()">
            <p class="title">Anticipo rentas de alquiler</p>
            <p class="subtitle">Evalúa a tus inquilinos y asegúrate de recibir todas las rentas.</p>
        </div>
        <div class="div" routerLink="tarjeta-pago-aplazado" (click)="hideMenu()">
            <p class="title">Tarjeta de pago aplazado</p>
            <p class="subtitle">Paga con las condiciones que tú eligas y ajústalas siempre que quieras.</p>
        </div>
    </div>
    <div class="third-content">
        <div>
            <h3>Recursos</h3>
            <a class="title" routerLink="simulador" (click)="hideMenu()">Simulador de préstamos <img src="../../../../assets/svg/chevron-right.svg" alt=""></a>
            <a class="title" routerLink="nuestras-tiendas" (click)="hideMenu()">Tiendas <img src="../../../../assets/svg/chevron-right.svg" alt=""></a>
        </div>
        <img routerLink="app" src="../../../../assets/img/img-landing/app-button.png" alt="">
    </div>
</div>

<div class="business-container" *ngIf="contentType === 'business'">
    <div class="first-content">
        <h3>Soluciones para empresas</h3>
        <div class="div" routerLink="concesionario-coches" (click)="hideMenu()">
            <p class="title">Concesionario Coches</p>
        </div>
        <div class="div">
            <p class="title">Concesionario Motos</p>
        </div>
        <div class="div">
            <p class="title">Comercio</p>
        </div>
        <div class="div">
            <p class="title">Clínica</p>
        </div>
        <div class="div">
            <p class="title">Club Deportivo</p>
        </div>
        <div class="div">
            <p class="title">E-commerce</p>
        </div>
        <div class="div">
            <p class="title">Centro educativo</p>
        </div>
        <div class="div">
            <p class="title">Circulante canal HORECA</p>
        </div>
    </div>
    <div class="third-content">
        <div>
            <h3>Recursos</h3>
            <p class="title" routerLink="app">Nuestra app <img src="../../../../assets/svg/chevron-right.svg" alt=""></p>
            <p class="title" routerLink="nuestras-tiendas">Tiendas <img src="../../../../assets/svg/chevron-right.svg" alt=""></p>
        </div>
        <a>
            <img src="../../../../assets/img/img-landing/app-business.png" alt="">
        </a>

    </div>
</div>

<div class="meet-container" *ngIf="contentType === 'us'">
    <div class="first-content">
        <h3>Conócenos</h3>
        <div class="div" routerLink="app" (click)="hideMenu()">
            <p class="title">Nuestra APP</p>
            <p class="subtitle">En Confía no existe el "papeleo". Todo lo que necesitas está en tu móvil.</p>
        </div>
        <div class="div" routerLink="sobre-nosotros" (click)="hideMenu()">
            <p class="title">¿Quiénes somos?</p>
            <p class="subtitle">Te contamos un poco más sobre nosotros para irnos conociendo mejor.</p>
        </div>
    </div>
</div>

<div class="login-container" *ngIf="contentType === 'login'">
    <div class="first-content">
        <h3>Accesos</h3>
        <div class="div">
            <p class="title" routerLink="login" (click)="hideMenu()">Mi panel de cliente</p>
        </div>
        <div class="div">
            <p class="title" routerLink="login" (click)="hideMenu()" >Mi panel de empresa</p>
        </div>
    </div>
</div>