import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HideShowAnimation } from '../../animations/hide-show';

@Component({
    selector: 'app-toolbar-alerts',
    templateUrl: './toolbar-alerts.component.html',
    styleUrls: ['./toolbar-alerts.component.scss'],
    animations: [HideShowAnimation],
    standalone: false
})
export class ToolbarAlertsComponent implements OnInit {

  @Input() status: string;
  @Input() alertMessage : string;
  @Output() alertClosed = new EventEmitter<boolean>();
  isVisible : boolean = true
  
  constructor() { }

  ngOnInit(): void {
    this.isVisible = true
    this.scrollError();
  }

  closeToolbar(){
    this.isVisible = false;
    this.alertClosed.emit(true);
  }

  scrollError(){
    setTimeout(() => {
      let el = document.getElementById('errorMessage');
      el?.scrollIntoView({behavior:"smooth"});
    }, 100);   
  } 
}
