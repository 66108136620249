export function toDateES(value: string) {
    const year = value.slice(0, 4);
    const month = value.slice(5, 7);
    const day = value.slice(8, 10);
    return (day + '/' + month + '/' + year)
}
export function toDateWithTimeES(value: string) {
    const year = value.slice(0, 4);
    const month = value.slice(5, 7);
    const day = value.slice(8, 10);
    const hour = value.slice(11, 13);
    const minute = value.slice(14, 16);
    return (day + '/' + month + '/' + year + ' ' + hour + ':' + minute)
}

export function toShortenText(value: any){
    if(value != null) {
        if(value.length > 10) {
            return (value.slice(0, 10) + '...');
        } else {
            return value;
        }
    } else {
        return null
    }
    
}

export function toShortenTextLarge(value: any){
    if(value != null) {
        if(value.length > 40) {
            return (value.slice(0, 40) + '...');
        } else {
            return value;
        }
    } else {
        return null
    }
}
