<div class="reg_container">
    <app-header-no-sidenav></app-header-no-sidenav>
    <div class="Page_container">
        <h2 class="affiliate-title-banner">Preferencias</h2>
        <div class="alert">
            <app-toolbar-alerts *ngIf="errorAlert && status == 'ko'" [status]="status"
                [alertMessage]="errorMessage"></app-toolbar-alerts>
        </div>
        <div class="total-container">
            <div class="preferences_container">
                <div class="single_container">
                    <h4 class="without_info" *ngIf="userType == 'afiliado'"> Datos de la empresa</h4>
                    <h4 class="without_info" *ngIf="userType == 'prestatario'"> Datos personales</h4>
                    <div *ngIf="loader" class="skeleton_container">
                        <ngx-skeleton-loader count="{{totalCount}}" appearance="line" [theme]="{'height': '30px'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div *ngIf="!loader && userType == 'afiliado' && userData" class="data">
                        <p>Responsable: {{ businessData.nombreRl}} {{ businessData.primerApellidoRl}} {{
                            businessData.segundoApellidoRl}}</p>
                        <p>Nombre comercial: {{ businessData.nombreComercial }}</p>
                        <p>Razón Social: {{ businessData.razonSocial }}</p>
                        <p>CIF: {{ businessData.cif }}</p>
                        <p>Teléfono: {{ businessData.telefonoMovil }}</p>
                        <p>Código afiliado: {{ userData.username }}</p>
                        <p>Fecha de apertura de la cuenta: {{toDate(businessData.createdAt) }}</p>
                        <p>Email gestión: {{ userData.email }}</p>
                        <div class="password">
                            <p>Contraseña</p>
                            <button class="stroked-button-small" (click)="resetPassword()">Cambiar contraseña</button>
                        </div>
                    </div>
                    <div *ngIf="!loader && userType == 'prestatario'" class="data">
                        <p>Nombre: {{userData.firstname}} {{userData.lastname}}</p>
                        <p>Cuenta: {{userData.username}}</p>
                        <p>NIE/NIF: {{userData.dni}}</p>
                        <p>Email: {{userData.email}}</p>
                        <p>Teléfono: {{ userData.phone }}</p>
                        <div class="password">
                            <p>Contraseña</p>
                            <button class="stroked-button-small" (click)="resetPassword()">Cambiar contraseña</button>
                        </div>
                    </div>
                </div>
                <div class="single_container" *ngIf="businessData && userType =='afiliado'">
                    <h4 class="without_info">Logotipo</h4>
                    <div class="logo-container">
                        <img *ngIf="businessData.logo != null" src="{{logoPath}}" alt="logo" />
                        <img *ngIf="businessData.logo == null" src="../../../../assets/img/perfil-borrower.png" alt="">
                    </div>
                    <div class="upload_box">
                        <div class="methods-payment-contain">
                            <div class="button-container">
                                <app-upload-files (file)="receiveFile($event)" [nameElement]="'logo'"
                                    [buttonText]="'Subir nuevo logo'"[afiliado]="'1'"
                                    [creditId]="businessData.id">
                                </app-upload-files>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preferences_container">
                <div class="single_container" *ngIf="userAccount && !userAccount.validated && userType === 'afiliado'">
                    <div class="without_info">
                        <h4>Justificante de cuenta bancaria</h4>
                        <mat-icon matTooltip="Tu cuenta bancaria en formato IBAN: ES..." matTooltipPosition="above">info
                        </mat-icon>
                    </div>
                    <div class="data">
                        <p>Debes subir un documento justificante de tu IBAN donde aparezca el nombre de la empresa y la
                            cuenta bancaria.</p>
                        <div class="button-container">
                            <app-upload-files [nameElement]="'iban'" [userId]="userId" [afiliado]="'1'"></app-upload-files>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>