<div class="reg_container">
    <app-header-no-sidenav></app-header-no-sidenav>

  <div class="Page_container">
    <div class="items-affiliate-contain">
      <p class="affiliate-title-banner">Ayúdanos a ayudarte</p>
      <app-toolbar-alerts class="app-toolbar" *ngIf="status" [alertMessage]="alertMessage"
        [status]="status"></app-toolbar-alerts>
      <div class="help_container">
        <div class="help_column">
          <h4>Escríbenos!</h4>
          <form [formGroup]="helpForm" novalidate class="text-form">
            <mat-form-field appearance="fill" color="primary" class="inputRow" (keyup)="onKey($event)">
              <mat-label>Escribe aquí tu pregunta</mat-label>
              <input matInput formControlName="text" #text>
              <mat-error *ngIf="!helpForm.get('text')?.valid && (helpForm.get('text')?.dirty || submitted)">Texto muy
                corto (mínimo 10 caracteres)</mat-error>
            </mat-form-field>
            <button class="main-color-button" (click)="onSubmit()" [disabled]="!helpForm?.valid">Enviar</button>
            <mat-spinner [diameter]="20" *ngIf="spinner"></mat-spinner>
          </form>
        </div>
        @if(userType !== 'prestatario') {
        <div *ngIf="loader" class="skeleton_container">
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{'height': '100%', 'border-radius': '9px'}">
          </ngx-skeleton-loader>
        </div>
        <div class="help_column" *ngIf="salesAgent">
          <h4>Habla con tu comercial</h4>
          @if(salesAgent.phone) {
          <p>{{ fullName }}</p>
          <p>{{ salesAgent.phone }}</p>
          <p>{{ salesAgent.email }}</p>
          } @else {
            <p >Teléfono: 935520004</p>}
         
        </div>
        }

      </div>
      <app-faq [statusBox]="statusBox"></app-faq>
    </div>
  </div>
</div>