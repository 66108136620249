
import { CreditData, InfoBorrower, Amortization, CotitularModel, CreditStatusModel } from './../../models/borrower/borrower';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BorrowerAbstractService } from './borrower.abstract-service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BorrowerService implements BorrowerAbstractService{

  constructor( private http: HttpClient) { 
  
  }
  public creditData(id: string): Observable<CreditData>{
    return this.http.get<CreditData>(`${environment.baseUrl}/api/creditos/${id}`)
  }
/*   public financingDetail(idBorrower: string): Observable<FinancingModel> {
    return this.http.get<FinancingModel>(`${environment.baseUrl}/prestatario${idBorrower}`)
  } */
  public infoBorrowers(id: string): Observable<InfoBorrower>{
    return this.http.get<InfoBorrower>(`${environment.baseUrl}/api/info_prestatarios/${id}`)
  }
  public getAmortizationZankActive(id: string): Observable<Amortization>{
    return this.http.get<Amortization>(`${environment.baseUrl}/api/amortizations?idZank=${id}&active=1`)
  }
  public getAmortizationActive(id: string): Observable<Amortization>{
    return this.http.get<Amortization>(`${environment.baseUrl}/api/creditos/${id}/cuotas?activa=1`)
    }
  public askForm(id: string, data: any): Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/borrowerForm/${id}`, data)
  }
  public cotForm(id: string, data: any, authorization?: any): Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/cotitularForm/${id}`, data, {headers: authorization})
  }
  
  public getPaymentMethods(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/users/${id}/payment_methods?pagination=false`)
  }
  public getPaymentMethodsOld(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/pay_methods?idZank=${id}&pagination=false`)
  }
  
  public sendContractCode(id: string): Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/sendContractCode/${id}`, id)
  }
  public sendInsuranceCode(id: string): Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/sendInsuranceCode/${id}`, id)
  }
  public verifyContractCode(id: string, data: any): Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/verifyContractCode/${id}`, data)
  }
  public verifyMandateCode(id: string, data: any): Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/verifyMandateCode/${id}`, data)
  }
  public verifyInsuranceCode(id: string, data: any): Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/verifyInsuranceCode/${id}`, data)
  }
  public generateLoanInstallments(id: string): Observable<any>{
    return this.http.post<any>(`${environment.baseUrl}/api/creditos/${id}/generateLoanInstallments`, id)
  }
  public generateLoanMandate(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/creditos/${id}/generateLoanMandate`)
  }
  public getAmortizationSimulation(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/tools/microadmin/advanceAmortizationSimulation/${id}`)
  }
  public updateStatusBorrower(id: string, data?: any): Observable<any>{
    return this.http.put<any>(`${environment.baseUrl}/api/updateStatus/${id}`, data)
  }
  public getCotitular(id: string): Observable<CotitularModel>{
    return this.http.get<CotitularModel>(`${environment.baseUrl}/api/cotitulars/${id}`)
  }
  public checkStatus(id: string): Observable<CreditStatusModel>{
    return this.http.get<CreditStatusModel>(`${environment.baseUrl}/api/creditos/${id}/checkStatus`)
  }
  public downloadCatContract(id: string, responseType: any, authorization?: any): Observable<any>{
    return this.http.post<any>(`${environment.baseUrl}/api/creditos/${id}/contractCatFile`, id,{responseType: responseType, headers: authorization}/*  { headers: new HttpHeaders({ "contract": "base64", "contractUrl": "url" })} */)
  }
  public checkAccess(id: string): Observable<any>{
    return this.http.get<any>(`${environment.baseUrl}/api/creditos/${id}/checkAccess`)
  }
  
  
  
}

/* /api/amortizations?idZank=116003&active=1&pagination=false */
