<!-- HEADER PUBLICO DESKTOP -->
<div class="wide header-fixed">
  <div
    [ngClass]="isBlueTheme ? 'header-landing blue-landing' : 'header-landing'"
    *ngIf="!isLoggedIn && !isLogin && !burgerMenuActive"
  >
    <div class="left-content">
      <img
        class="logo"
        [src]="
          isBlueTheme
            ? '../../../../assets/img/logo-white.svg'
            : '../../../../assets/img/logo-blue.svg'
        "
        alt=""
        href="/"
        (click)="closeAllDropdowns()"
      />
      <div class="dropdown-container">
        <button
          class="dropdown-blue"
          (click)="dropdownService.toggleDropdownParticular($event)"
        >
          <p>
            Para clientes
            <img
              [src]="
                isBlueTheme
                  ? '../../../../assets/svg/dropdown-white.svg'
                  : '../../../../assets/svg/dropdown.svg'
              "
              alt=""
            />
          </p>
        </button>
        <app-dropdown-content
          (blur)="closeAllDropdowns()"
          *ngIf="dropdownService.showDropdownParticular"
          [contentType]="'particular'"
        ></app-dropdown-content>
      </div>
      <div class="dropdown-container">
        <button
          class="transparent-button"
          (click)="dropdownService.toggleDropdownBusiness($event)"
        >
          <p>
            Para empresas
            <img
              [src]="
                isBlueTheme
                  ? '../../../../assets/svg/dropdown-white.svg'
                  : '../../../../assets/svg/dropdown.svg'
              "
              alt=""
            />
          </p>
        </button>
        <app-dropdown-content
          *ngIf="dropdownService.showDropdownBusiness"
          [contentType]="'business'"
        ></app-dropdown-content>
      </div>
      <div class="dropdown-container">
        <button
          class="transparent-button"
          (click)="dropdownService.toggleDropdownUs($event)"
        >
          <p>
            Conócenos
            <img
              [src]="
                isBlueTheme
                  ? '../../../../assets/svg/dropdown-white.svg'
                  : '../../../../assets/svg/dropdown.svg'
              "
              alt=""
            />
          </p>
        </button>
        <app-dropdown-content
          *ngIf="dropdownService.showDropdownUs"
          [contentType]="'us'"
        ></app-dropdown-content>
      </div>
    </div>
    <div class="right-content">
      <div class="dropdown-container">
        <button class="transparent-button" routerLink="/login">
          <p>Ya soy cliente</p>
        </button>
        <!-- <app-dropdown-content
      *ngIf="dropdownService.showDropdownLogin"
        [contentType]="'login'"
      ></app-dropdown-content> -->
      </div>
      <button
        class="main-dark-button"
        routerLink="/registro"
        (click)="closeAllDropdowns()"
      >
        <p>Contacta</p>
      </button>
      <button
        class="transparent-button"
        routerLink="ayuda"
        (click)="closeAllDropdowns()"
      >
        <img
          [src]="
            isBlueTheme
              ? '../../../../assets/svg/help-white.svg'
              : '../../../../assets/svg/help.svg'
          "
          alt=""
        />
        <p>Servicio de Atención al Cliente</p>
      </button>
    </div>
  </div>
</div>

<!-- HEADER PUBLICO BURGER -->
<div class="header-fixed">
  <div
    [ngClass]="
      isBlueTheme
        ? 'header-landing-burger blue-landing-burger'
        : 'header-landing-burger'
    "
    *ngIf="!isLoggedIn && !isLogin && burgerMenuActive"
  >
    <div class="left-content">
      <div
        class="burger-menu"
        *ngIf="burgerMenuActive && !isOpenBurger"
        (click)="showMenu()"
      >
        <img src="../../../../assets/svg/Menu-b.svg" alt="Menu" />
      </div>
      <div
        class="burger-menu"
        *ngIf="burgerMenuActive && isOpenBurger"
        (click)="hideMenu()"
      >
        <img src="../../../../assets/svg/close-black.svg" alt="Menu" />
      </div>
      <img
        class="logo"
        [src]="
          isBlueTheme
            ? '../../../../assets/img/logo-white.svg'
            : '../../../../assets/img/logo-blue.svg'
        "
        alt=""
        routerLink="/"
        (click)="hideMenu()"
      />
    </div>
    <div
      *ngIf="burgerMenuActive && isOpenBurger"
      class="burger-content"
      [@fadeInLeftOnEnter]
      [@fadeOutLeftOnLeave]
    >
      <div class="burger-links">
        <div class="content-sidenav">
          <div class="toggle-buttons">
            <button
              class="button-toggle"
              [ngClass]="{
                'button-toggle-active': activeLinkList === 'clients'
              }"
              (click)="setActiveLinkList('clients')"
            >
              <p>Para clientes</p>
            </button>

            <button
              class="button-toggle"
              [ngClass]="{
                'button-toggle-active': activeLinkList === 'business'
              }"
              (click)="setActiveLinkList('business')"
            >
              <p>Para empresas</p>
            </button>

            <button
              class="button-toggle"
              [ngClass]="{ 'button-toggle-active': activeLinkList === 'about' }"
              (click)="setActiveLinkList('about')"
            >
              <p>Conócenos</p>
            </button>
          </div>
          <div *ngIf="activeLinkList === 'clients'" class="linklist-div">
            <h3 class="title-link" (click)="toggleClient()">
              Soluciones para particulares
              <img src="../../../../assets/svg/dropdown.svg" alt="" />
            </h3>
            <div
              class="itmes"
              *ngIf="isClientOpen"
              [@expandOnEnter]
              [@collapseOnLeave]
            >
              <div
                *ngFor="let item of publicClientLinklist"
                class="burger-item"
              >
                <div
                  class="link"
                  routerLink="{{ item.route }}"
                  routerLinkActive="activeRoute"
                  (click)="hideMenu()"
                >
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.subtitle }}</p>
                </div>
              </div>
            </div>
            <div class="resources">
              <h3 class="title-link" (click)="toggleResourcesClient()">
                Recursos
                <img src="../../../../assets/svg/dropdown.svg" alt="" />
              </h3>
              <div
                class="resources-link"
                *ngIf="isResourcesClientOpen"
                [@expandOnEnter]
                [@collapseOnLeave]
              >
                <h3 (click)="hideMenu()" routerLink="/simulador">
                  Simulador de préstamos
                </h3>
                <h3 (click)="hideMenu()" routerLink="/nuestras-tiendas">
                  Tiendas
                </h3>
                <h3 (click)="hideMenu()" routerLink="/app">Nuestra App</h3>
              </div>
            </div>
          </div>
          <div *ngIf="activeLinkList === 'business'" class="linklist-div">
            <h3 class="title-link" (click)="toggleBusiness()">
              Soluciones para empresas
              <img src="../../../../assets/svg/dropdown.svg" alt="" />
            </h3>
            <div
              class="items"
              *ngIf="isBusinessOpen"
              [@expandOnEnter]
              [@collapseOnLeave]
            >
              <div
                *ngFor="let item of publicBusinessLinklist"
                class="burger-item"
              >
                <div
                  class="link"
                  routerLink="{{ item.route }}"
                  routerLinkActive="activeRoute"
                  (click)="hideMenu()"
                >
                  <h3>{{ item.title }}</h3>
                </div>
              </div>
            </div>
            <div class="resources">
              <h3 class="title-link" (click)="toggleResourcesBusiness()">
                Recursos
                <img src="../../../../assets/svg/dropdown.svg" alt="" />
              </h3>
              <div
                class="resources-link"
                *ngIf="isResourcesBusinessOpen"
                [@expandOnEnter]
                [@collapseOnLeave]
              >
                <h3 (click)="hideMenu()" routerLink="/nuestras-tiendas">
                  Tiendas
                </h3>
                <h3 (click)="hideMenu()" routerLink="/app">Nuestra App</h3>
              </div>
            </div>
          </div>
          <div *ngIf="activeLinkList === 'about'" class="linklist-div">
            <h3 class="title-link" (click)="toggleAbout()">
              Conócenos <img src="../../../../assets/svg/dropdown.svg" alt="" />
            </h3>
            <div
              class="items"
              *ngIf="isAboutOpen"
              [@expandOnEnter]
              [@collapseOnLeave]
            >
              <div *ngFor="let item of publicAboutLinkList" class="burger-item">
                <div
                  class="link"
                  routerLink="{{ item.route }}"
                  routerLinkActive="activeRoute"
                  (click)="hideMenu()"
                >
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.subtitle }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="help-link" routerLink="ayuda" (click)="hideMenu()">
          <h3>
            <img src="../../../../assets/svg/help.svg" alt="" />Servicio de
            Atención al Cliente
          </h3>
        </div>
      </div>
      <div class="footer-links">
        <button
          class="contact-button"
          (click)="hideMenu()"
          routerLink="/registro"
        >
          <p>Contacta</p>
        </button>
        <button
          class="contact-button-stroked"
          (click)="hideMenu()"
          routerLink="login"
        >
          <p>Ya soy cliente</p>
        </button>
      </div>
    </div>
    <div class="right-content">
      <button
        class="main-dark-button"
        routerLink="/ayuda"
        (click)="hideMenu()"
      >
        <p>Contacta</p>
      </button>
      <button class="btn-user-light" (click)="hideMenu()" routerLink="login">
        <img src="../../../../assets/svg/user.svg" alt="" />
      </button>
      <button class="btn-user-dark" (click)="hideMenu()" routerLink="login">
        <img src="../../../../assets/svg/user-white.svg" alt="" />
      </button>
    </div>
  </div>
</div>
