<div class="headercontainer">
    <div class="header-main-contain">
      <div class="imgs-contain">
<!--         <img
          class="logo-confia"
          src="../../../../assets/LG-Confia-Color-01.svg"
          alt="logo confia"
          (click)="navigateTo(idBorrower)"
        /> -->
      <!--   <img class="logo-afiliado" src="https://dev.zank.com.es/logos/{{businessLogo}}" alt="logo afiliado" /> -->
      </div>
      <nav class="header-nav" *ngIf="userType == 'afiliado' && linksEnabled">
        <ul class="nav-list">
          <a class="nav-item" [routerLink]="'/afiliado'"  routerLinkActive="activeRoute" [routerLinkActiveOptions]="{ exact: true }">
            Panel de control
          </a>
          <a
          *ngIf="linksEnabled.simulador"
            class="nav-item"
            [routerLink]="'/afiliado/simulador'"
            routerLinkActive="activeRoute"
            
          >
            Simulador
          </a>
          <a
            *ngIf="linksEnabled.transfers"
            class="nav-item"
            [routerLink]="'/afiliado/transferencias'"
            routerLinkActive="activeRoute"
            
          >
            Transferencias
          </a>
          <a
          *ngIf="linksEnabled.cobros"
          class="nav-item"
          [routerLink]="'/afiliado/cobros'"
          routerLinkActive="activeRoute"
          
        >
          Cobros
        </a>
          <a
          *ngIf="linksEnabled.usuarios"
            class="nav-item"
            [routerLink]="'/afiliado/usuarios'"
            routerLinkActive="activeRoute"
          >
            Vendedores
          </a>
          <a
          *ngIf="linksEnabled.links"
            class="nav-item"
            [routerLink]="'/afiliado/enlaces'"
            routerLinkActive="activeRoute"
          >
            Enlaces
          </a>
          <a 
          *ngIf="linksEnabled.reports"
          class="nav-item"
          [routerLink]="'/afiliado/reports'"
          routerLinkActive="activeRoute"
        >
          Informes
        </a>
        </ul>
      </nav>
      <mat-divider color="primary"></mat-divider>
    </div>
  </div>