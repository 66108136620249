<div class="content-faq">
<mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false"
                         class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
            ¿Qué significan los diferentes estados de mis solicitudes?
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="body">
        <div class="status_legend" *ngFor="let item of statusBox">
          <p class="status_box" [ngStyle]="{'background-color': item.color}">{{item.statusTitle}}</p>
          <p class="text_legend">{{item.text}}</p>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>