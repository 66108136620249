import { BehaviorSubject, concatMap, Observable, of, tap } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { getFase } from '../../extensions/status.extensions';
import { Router } from '@angular/router';
import { AffiliateService } from '../affiliate/affiliate.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  userType$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  name$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  logo$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public creditList: any[]
  public activeCreditList: any[]
  constructor(private userService: UserService, private router: Router, private affiliateService: AffiliateService) {
    this.setUserType(localStorage.getItem('userType'))
    localStorage.getItem('userType') == 'prestatario' ? this.setNameSession(localStorage.getItem('userName')) : this.setNameSession(localStorage.getItem('corporationName'))
  }

  getNameSession(): Observable<string> {
    return this.name$.asObservable();
  }

  setNameSession(element: string) {
    this.name$.next(element);
  }

  getLogoSession(): Observable<string> {
    return this.logo$.asObservable();
  }

  setLogoSession(element: string) {
    localStorage.setItem('logo', element)
    this.logo$.next(element);
  }

  getUserType(): Observable<string> {
    return this.userType$.asObservable();
  }

  setUserType(element: string) {
    this.userType$.next(element);
  }

  getUserData(email?: string) {
    let userType;
    this.setUserType('');
    this.setNameSession('');

    this.userService.getUserByEmail(email).pipe(
      concatMap((res) => {
        const userData = res["hydra:member"][0]
        localStorage.setItem('userType', userData.tipo)
        this.setUserType(userData.tipo)
        const userId = userData.id;
        localStorage.setItem('userId', userId);
        localStorage.setItem('userName', userData.firstname)

        userType = localStorage.getItem('userType')
        if (userType === 'prestatario') {
          this.setNameSession(userData.firstname);
          if (userData.afiliado) {
            localStorage.setItem('affiliateId', this.getIdFromPath(userData.afiliado));
            return this.userService.getCreditList(userId)

          } else {
            this.router.navigate(['/prestatario/inactivo'])
            return of(null);
          }
        } else {
          localStorage.setItem('affiliateId', this.getIdFromPath(userData.afiliado));
          return of(null);
        }
      }),
      concatMap((creditList) => {
        if (creditList) {
          this.creditList = creditList["hydra:member"];
          const activePhases = [ 'FA', 'FS', 'FF', 'FE'];
          const activeStatus = ['EAM', 'RET', 'FAL']
          /*    this.activeCredit = this.creditList.find(item => activePhases.includes(getFase(item.estadoCd))); */

          //!listado de créditos ACTIVOS (preguntar si tienen que ser activos o cualquiera)

          this.activeCreditList = this.creditList.filter(item => activePhases.includes(getFase(item.estadoCd)) || activeStatus.includes(item.estadoCd));
        }
        return this.affiliateService.getAffiliate(localStorage.getItem('affiliateId'))
      })
    ).subscribe((data) => {
      localStorage.setItem('financedRate', data.financedRate)
      localStorage.setItem('formacionAsnef', data.formacionAsnef)
      const businessId = this.getIdFromPath(data!.datosEmpresa)
      const linksEnabled = {
        inversores: data.usuarios,
        links: data.links,
        simulador: data.simulador,
        cobros: data.cobrosEnabled,
        reports: data.reports,
        usuarios: data.usuarios,
      }
      this.affiliateService.getAffiliateBasicInfo(localStorage.getItem('affiliateId')).pipe(
        tap((res) => {
          linksEnabled['transfers'] = res.transactionsEnabled;
          localStorage.setItem('linksEnabled', JSON.stringify(linksEnabled))
          /* if(localStorage.getItem('userType') === 'afiliado') {
            this.setAffiliateLinks()
          } */

          const currentPath = environment.baseUrl
          this.setLogoSession(currentPath +'/logos/'+ res.logo)
        })
      ).subscribe((fin) => {
        if (userType != 'prestatario') {
          this.setNameSession(data.corporationName)
        }
  
        localStorage.setItem('businessId', this.getIdFromPath(data.datosEmpresa));
        localStorage.setItem('estadoCd', data.estadoCd);
        localStorage.setItem('delivery', data.immediateDelivery);
        localStorage.setItem('corporationName', data.corporationName);
        localStorage.setItem('datosEmpresa', data.datosEmpresa);
        localStorage.setItem('associatedInvestor', this.getIdFromPath(data.associatedInvestor));
        localStorage.setItem('salesAgentId', this.getIdFromPath(data.zankSalesAgent));
  
        if (userType == 'prestatario') {
          /*  if (this.activeCredit) {
             this.router.navigate(['/prestatario'], { queryParams: { id: this.activeCredit.id } });
           } else {
             this.router.navigate(['/prestatario/inactivo']);
           } */
          if (this.activeCreditList.length === 1) {
            this.router.navigate(['/prestatario'], { queryParams: { id: this.activeCreditList[0].id } });
          } else if (this.activeCreditList.length > 1) {
            localStorage.setItem('multipleCredits', 'true')
            this.router.navigate(['/prestatario']);
          } else if (this.activeCreditList.length === 0) {
            this.router.navigate(['/prestatario/inactivo']);
          }
        } else {
          this.router.navigate(['/afiliado']);
        }
      })


    }, err => {
      /* console.log(err) */
    });
  }

  public getIdFromPath(path: string): string {

    const segments = path?.split("/");
    return segments?.[segments.length - 1];
  }

}
