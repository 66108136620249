<div class="wide">
    <div class="footer">
        <div class="footer-content">
            <div class="links-container">
                <div class="first-container">
                    <img src="../../../../assets/img/logo-blue.svg" alt="">
                </div>
                <div class="second-container">
                    <h3>Particulares</h3>
                    <a href="/prestamo-de-consumo">Préstamo Consumo</a>
                    <a href="/prestamo-personal">Préstamo Personal</a>
                    <a href="/reunificacion-deudas">Reunifica tus deudas</a>
                    <a href="/anticipo-alquiler">Anticipo rentas de alquiler</a>
                    <a href="/ahorro-inversion">Ahorro</a>
                    <a href="/tarjeta-pago-aplazado">Tarjeta de crédito o pago a plazos</a>
                    <a href="/pagares">Pagarés</a>
                </div>
                <div class="third-container">
                    <h3>Empresas</h3>
                    <a href="/concesionario-coches">Concesionario Coches</a>
                    <a>Concesionario Motos</a>
                    <a>Comercio</a>
                    <a>Clínica</a>
                    <a>Club Deportivo</a>
                    <a>E-commerce</a>
                    <a>Centro educativo</a>
                    <a>Circulante canal HORECA</a>
                </div>
                <div class="fourth-container">
                    <h3>Conócenos</h3>
                    <a href="/sobre-nosotros">¿Quiénes somos?</a>
                    <a href="/app">Nuestra app</a>
                    <a href="/ayuda">Ayuda</a>
                </div>
            </div>
            <div class="apps-container">
<!--                 <div class="circle">
                    <img src="../../../../assets/svg/brand-instagram.svg" alt="">
                </div> -->
<!--                 <div class="circle">
                    <img src="../../../../assets/svg/brand-youtube.svg" alt="">
                </div> -->
                <div class="circle">
                    <img src="../../../../assets/svg/brand-linkedin.svg" alt="">
                </div>
<!--                 <div class="circle">
                    <img src="../../../../assets/svg/brand-x.svg" alt="">
                </div> -->
            </div>
            <div class="terms-container">
                <div class="terms">
                    <a href="/condiciones-generales">Aviso Legal</a>
                    <a href="/cookies">Política de Cookies</a>
                    <a href="/politica-privacidad">Política de privacidad</a>
                    <a href="/condiciones-uso">Términos y condiciones</a>
                </div>
                <p>© 2024 Confia. All rights reserved.</p>
            </div>
        </div>
    </div>
</div>
