import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { finalize, Observable } from "rxjs";
import { SpinnerService } from "../services/spinner/spinner.service";
import { ActivatedRoute, Router } from "@angular/router";


@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

    public activeRequests: number = 0;
    skeleton: any

    constructor(private spinnerService: SpinnerService, private router: Router) {
      
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       
        this.spinnerService.skeletonLoading$.subscribe(
            res => {
                this.skeleton = res

            }
        )
        if (this.skeleton) {
            this.spinnerService.hideLoading()
            return next.handle(request)
        } else {
            if (this.activeRequests > 0 && !this.spinnerService.loading) {
                this.spinnerService.showLoading();
            }
           

            if (!request.url?.includes('checkStatus') && !request.url?.includes('/public/simulate') && !request.url?.includes('/checkExistingLoan?') && !this.router.url.includes('/prestatario?')) {
                this.activeRequests++;
            }

            return next.handle(request).pipe(
                finalize(() => {
                    if (!request.url?.includes('checkStatus') && !request.url?.includes('/public/simulate') && !request.url?.includes('checkExistingLoan') && !this.router.url.includes('/prestatario?')) {

                        if (this.activeRequests > 0) {
                            this.activeRequests--;
                        }

                        if (this.activeRequests === 0) {    
                                this.spinnerService.hideLoading();
                        }
                    }
                })
            )
        }
    }
}
