<div class="wide-dark header-fixed">
    <div class="dark-header">
      <div class="left-content">
        <img class="logo" src="../../../../assets/img/logo-white.svg" alt="" routerLink="/" />
        <div class="toggle-dark-mode">
          <!-- <div class="btn">
            <input
              type="checkbox"
              name="check"
              id="check"
              (click)="togglePosition()"
            />
            <label for="check">
              <div class="box" [ngClass]="{ 'box-end': isChecked }">
                <div class="ball"></div>
                <div class="scenary">
                  <div class="moon">
                    <img src="../../../../assets/svg/moon.png" alt="" />
                  </div>
                  <div class="sun">
                    <img src="../../../../assets/svg/Vector.svg" alt="" />
                  </div>
                </div>
              </div>
            </label>
          </div> -->
        </div>
      </div>
      <div class="right-content">
        <div class="item" routerLink="/documentos">
          <img src="../../../../assets/svg/document.svg" alt="" />
          <p>Documentos</p>
        </div>
        <!-- <div class="item" routerLink="notificaciones">
          <img src="../../../../assets/svg/bell-white.svg" alt="" />
          <p>Notificaciones</p>
        </div> -->
        <div class="deployable" (click)="toggleDeploy()" #menuDeploy>
          <p class="name">{{ name.charAt(0) }}</p>
          <img src="../../../../assets/svg/chevron-down.svg" alt="" />
          <div *ngIf="deploy" class="menu-deploy">
            <div class="list-item" (click)="navigateTo('preferencias')">
              <img src="../../../../assets/svg/settings.svg" alt="" />
              <p>Preferencias</p>
            </div>
            <div class="list-item" (click)="navigateTo('ayuda')">
              <img src="../../../../assets/svg/ask.svg" alt="" />
              <p>Ayuda</p>
            </div>
            <div class="line"></div>
            <div class="list-item" (click)="logout()">
              <img src="../../../../assets/svg/logout.svg" alt="" />
              <p>Cerrar sesión</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  