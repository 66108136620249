<div (window:resize)="onResize($event)">
    <div *ngIf="visible" class="burger_menu">
        <mat-toolbar class="toolbar_close" [ngClass]="{
            'backgroundAffiliate':afilliateType,
            'backgroundBorrower':borrowerType,
            'backgroundLanding':!afilliateType && !borrowerType
        }">
            <img src="../../../../assets/img/logoBlancoVector.svg" alt="logo_confia">
            <div>
                <button mat-icon-button (click)="toggle()"*ngIf="!isOpen">
                    <mat-icon>menu</mat-icon>
                </button>
                <button mat-icon-button (click)="toggle()" *ngIf="isOpen">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-toolbar>     
        <mat-toolbar class="toolbar_open" [ngClass]="{
            'backgroundAffiliate':afilliateType,
            'backgroundBorrower':borrowerType,
            'backgroundLanding':!afilliateType && !borrowerType
        }" 
       [@openCloseBurgerMenu]="{
        value: isOpen ? 'top' : 'bottom', 
        params: { topValue: topValue }
      }">   
            <div>
                <p (click)="toggle()" class="nav_btn" routerLink="afiliado" *ngIf="userType == 'afiliado'">PANEL DE CONTROL</p>
                <p (click)="toggle()" class="nav_btn" routerLink="afiliado/simulador" *ngIf="userType == 'afiliado' && enabledPaths && enabledPaths.simuladorEnabled == 'true'">SIMULADOR</p>
                <p (click)="toggle()" class="nav_btn" routerLink="afiliado/transferencias"*ngIf="userType == 'afiliado'">TRANSFERENCIAS</p>
                <p (click)="toggle()" class="nav_btn" routerLink="afiliado/cobros" *ngIf="userType == 'afiliado'  && enabledPaths && enabledPaths.cobrosEnabled == 'true' ">COBROS</p>
                <p (click)="toggle()" class="nav_btn" routerLink="afiliado/usuarios" *ngIf="userType == 'afiliado' && enabledPaths && enabledPaths.usuariosEnabled == 'true' ">USUARIOS</p>
                <p (click)="toggle()" class="nav_btn" routerLink="afiliado/enlaces" *ngIf="userType == 'afiliado' && enabledPaths && enabledPaths.linksEnabled== 'true'">ENLACES</p>
                <p (click)="toggle()" class="nav_btn" routerLink="afiliado/reports" *ngIf="userType == 'afiliado' && enabledPaths && enabledPaths.reportsEnabled== 'true'">INFORMES</p>
                <p (click)="toggle()" class="nav_btn" routerLink="ayuda">AYUDA</p>
                <p (click)="toggle()" class="nav_btn" routerLink="documentos" *ngIf="userType !== 'comercial'">DOCUMENTOS</p>
                <p (click)="toggle()" class="nav_btn" routerLink="{{userType}}/preferencias">PREFERENCIAS</p>
                <p (click)="toggle();navigateTo()" class="nav_btn">{{name}}</p>
                <p (click)="toggle();logout()" class="nav_btn">SALIR</p>
                
            </div>
            <div *ngIf="footerMenu" class="footer_header">
                <mat-icon (click)="hideFooter()">close</mat-icon>
                <a routerLink="contactanos">Contáctanos</a>
                <a routerLink="condiciones-uso">Info Legal</a>
                <a routerLink="mapa-web">Mapa Web</a>
                <a routerLink="diccionario">Diccionario</a>
            </div>        
        </mat-toolbar>   
    </div>
    <div class="nav" *ngIf="!visible" [ngClass]="{
        'backgroundAffiliate':afilliateType,
        'backgroundBorrower':borrowerType,
        'backgroundLanding':!afilliateType && !borrowerType
    }">
        <div class="loginNav_container" *ngIf="!hiddenNav">
            <mat-icon (click)="hideLogin()">close</mat-icon>
            <app-login-form
            [hiddenText]="true">
            </app-login-form>
        </div>
        <div class="nav_account" *ngIf="hiddenNav">
            <button mat-icon-button aria-label="" routerLink="ayuda">
                <mat-icon>help</mat-icon>
            </button>
            <button mat-icon-button aria-label="" routerLink="documentos" *ngIf="userType !== 'comercial'">
                <mat-icon>description</mat-icon>
            </button>
            <button mat-icon-button aria-label="" routerLink="preferencias">
                <mat-icon>settings</mat-icon>
            </button>
            <div class="username_btn" (click)="navigateTo()">
                <mat-icon>person</mat-icon>
                <p>{{name}}</p>
            </div>
            <button mat-button (click)="logout()">SALIR</button>
        </div>
    </div>
</div>
