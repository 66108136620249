import { Router } from '@angular/router';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { BorrowerService } from 'src/app/core/services/borrower/borrower.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatusBorrowerService {

  public statusBorrower$: BehaviorSubject<any> = new BehaviorSubject('');
  private sub: Subscription; // Cambiar el tipo de `sub` a `Subscription`
  private interval$: Observable<number>; // El Observable de intervalo
  private previousState: any;

  constructor(private borrowerService: BorrowerService, public router: Router) {
    this.interval$ = interval(3000)
  }

  public subSubscribe() {
   /*  console.log(this.sub) // Almacenar la Subscription devuelta por subscribe() */
    this.sub = this.interval$.subscribe(() => {
    if (localStorage.getItem('idBorrower')) {
      this.borrowerService.checkStatus(localStorage.getItem('idBorrower')).subscribe({
        complete: () => { },
        error: (err) => {
          console.error(err);
        },
        next: (res) => {
          //comparar dos objetos entre sí
          const areEqual = (obj1, obj2) =>
            JSON.stringify(obj1) === JSON.stringify(obj2); 

            if (this.previousState == '' || !areEqual(this.previousState, res)) {
            this.emitStatus(res);
            this.previousState = res;
          }
        },
      });
    }
  });
  }

  /*    //comparar dos objetos entre sí
            const areEqual = (obj1, obj2) =>
            JSON.stringify(obj1) === JSON.stringify(obj2); 

            if (this.previousState == '' || !areEqual(this.previousState, res)) { */

  public subUnsubscribe() {
    if (this.sub) {
      this.sub.unsubscribe();
      this.emitStatus('')
      this.previousState = ''

    }
  }

  public checkStatus(): Observable<any> {
    return this.statusBorrower$.asObservable();
  }
  public emitStatus(estado: any): void {
    this.statusBorrower$.next(estado);

  }

}
