
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IbanPanelComponent } from './layout/iban-panel/iban-panel.component';
import { HeaderAmortizationComponent } from './layout/header-amortization/header-amortization.component';
import { HeaderLogginInComponent } from './layout/header-loggin-in/header-loggin-in.component';
import { MaterialModule } from './material.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent, FooterLandingComponent, HeaderAffiliateComponent } from './layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthGuard } from 'src/app/core/services/guards/auth.guard';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { SpinnerInterceptor } from '../core/interceptors/spinner.interceptor';
import { ToolbarAlertsComponent } from './components/toolbar-alerts/toolbar-alerts.component';
import { environment } from 'src/environments/environment';
import { OrderByPipe } from './pipes/order-by.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { HeaderNoSidenavComponent } from './layout/header-no-sidenav/header-no-sidenav.component';
import { DropdownContentComponent } from '../modules/landing/dropdown-content/dropdown-content.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { UserPreferencesComponent } from './components/user-preferences/user-preferences.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { HelpComponent } from './components/help/help.component';
import { FaqComponent } from './components/help/faq/faq.component';
import { HeaderPrivateDesktopComponent } from './layout/header-private-desktop/header-private-desktop.component';
import { HeaderPrivateBurgerComponent } from './layout/header-private-burger/header-private-burger.component';
import { HeaderPublicComponent } from './layout/header-public/header-public.component';
import { LogosCarouselComponent } from './components/logos-carousel/logos-carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';


const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({ declarations: [
        LoginFormComponent,
        FooterLandingComponent,
        HeaderAffiliateComponent,
        ContactFormComponent,
        HeaderLogginInComponent,
        HeaderAmortizationComponent,
        IbanPanelComponent,
        SpinnerComponent,
        UploadFilesComponent,
        ToolbarAlertsComponent,
        OrderByPipe,
        SidenavComponent,
        HeaderNoSidenavComponent,
        DropdownContentComponent,
        UserPreferencesComponent,
        HelpComponent,
        ChangePasswordComponent,
        FaqComponent,
        HeaderPrivateBurgerComponent,
        HeaderPrivateDesktopComponent,
        HeaderPublicComponent,
        LogosCarouselComponent
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PdfViewerModule,
        FooterLandingComponent,
        HeaderAffiliateComponent,
        LoginFormComponent,
        ContactFormComponent,
        HeaderLogginInComponent,
        HeaderAmortizationComponent,
        IbanPanelComponent,
        SpinnerComponent,
        UploadFilesComponent,
        ToolbarAlertsComponent,
        OrderByPipe,
        NgxSkeletonLoaderModule,
        SidenavComponent,
        HeaderNoSidenavComponent,
        DropdownContentComponent,
        UserPreferencesComponent,
        HelpComponent,
        ChangePasswordComponent,
        FaqComponent,
        HeaderPrivateDesktopComponent,
        HeaderPrivateBurgerComponent,
        HeaderPublicComponent,
        LogosCarouselComponent
    ], 
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MaterialModule,
        NgxSkeletonLoaderModule,
        PdfViewerModule,
        CarouselModule
      ], 
        providers: [
        provideHttpClient(),
        { provide: LOCALE_ID, useValue: 'es-ES' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class SharedModule { }
